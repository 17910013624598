<template>
    <v-app>
        <!-- <Loader /> -->
        <Snackbar />
        <Navigation v-if="isAuthenticated && ready"/>
        <router-view v-if="ready"/>
        <v-row v-if="!ready" justify="center" align="center">
            <v-container>
                <v-progress-linear v-model="progress" color="black"/>
            </v-container>
        </v-row>
    </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    data () {
        return {
            ready: false,
            progress: 0,
        }
    },
    computed: {
        ...mapGetters({
            isAuthenticated: 'isAuthenticated'
        })
    },
    mounted () {
        this.initClient()
    },
    methods: {
        initClient(){
            this.progress = 25
            this.$store.dispatch('clientsRequest', {url: location.host}).then(()=>{
                this.progress = 50
                let client = this.$store.getters.getClient()
                if(client.theming_options){
                    this.$vuetify.theme.themes.light.primary = client.theming_options.primary
                    this.$vuetify.theme.themes.light.secondary = client.theming_options.secondary
                }
                document.title = this.$store.getters.getClient().name
                this.progress = 100
                this.ready = true
            }).catch( (err) => {
                console.log('error catched', err)
            })
        }
    }
}
</script>