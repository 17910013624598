import Vue from 'vue'
import Errors from './../../modules/errors';

const state = {
    access_token: localStorage.getItem('access_token') || '',
    status: '',
    hasLoadedOnce: false,
    errors: new Errors()
}

const getters = {
    isAuthenticated: state => !!state.access_token,
    getToken: state => state.access_token
    // authStatus: state => state.status,
    // authErrors: state => state.errors,
}

const actions = {
    authRequest: ({commit, dispatch}, credentials) => {
        let data = {
            'username': credentials.email,
            'password': credentials.password,
            'grant_type': 'password',
            'client_id': process.env.VUE_APP_API_OAUTH_ID,
            'client_secret': process.env.VUE_APP_API_OAUTH_SECRET
        }   

        return new Promise((resolve, reject) => {
            commit('authRequest');
            Vue.axios.post('/oauth/token', data)
                .then(async (resp) => {
                    let access_token = resp.data.access_token;
                    localStorage.setItem('access_token',access_token);
                    Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;

                    commit('authSuccess', access_token);
                    commit('setSnack', {'type': 'success', 'msg': 'Successfully loggued'});
                    await dispatch('profileRequest');
                    resolve(access_token);
                })
                .catch((err) => {
                    commit('authError', err.response.data.message || err.response.data);
                    localStorage.removeItem('access_token');
                    commit('setSnack', {'type': 'red', 'msg': err.response.data.message || err.response.data});
                    reject(err);
                })
        })
    },
    authLogout: ({commit}) => {
        let data = {
            'client_id': process.env.VUE_APP_API_OAUTH_ID
        }
        return new Promise((resolve, reject) => {
            Vue.axios.post('/oauth/logout', data)
                .then(() => {
                    localStorage.removeItem('access_token');
                    commit('authLogout');
                    commit('profileRemove');
                    resolve();
                })
                .catch((err) => {
                    console.log('authLogout err', err.response)
                    commit('authError', err.response.data.message || err.response.data);
                    reject(err);
                });
        })
    },
    resetPassword: ({commit}, credentials) => {
        let data = {
            'email': credentials.email,
        }

        return new Promise((resolve, reject) => {
            Vue.axios.post('/api/password/email', data)
                .then(() => {
                    commit('setSnack', {'type': 'success', 'msg': 'Reset password link successfully send'});
                    resolve();
                })
                .catch((err) => {
                    commit('setSnack', {'type': 'red', 'msg': err.response.data});
                    reject(err);
                });
        })
    },
    editPassword ({commit}, credentials) {
        let data = {
            'email': credentials.email,
            'token': credentials.token,
            'password': credentials.password,
            'password_confirmation': credentials.password_confirmation,
        }

        return new Promise((resolve, reject) => {
            Vue.axios.post('/api/password/reset', data)
                .then(result => result.data)
                .then(result => {
                    commit('setSnack', {'type': 'success', 'msg': 'Password successfully updated'});
                    resolve(result.data)
                })
                .catch(err => {
                    commit('setSnack', {'type': 'red', 'msg': err.response.data});
                    reject(err)
                })
        })
    }
}

const mutations = {
    authRequest: (state) => {
        state.status = 'loading';
    },
    authSuccess: (state, access_token) => {
        state.status = 'success';
        state.access_token = access_token;
        state.hasLoadedOnce = true;
    },
    authError: (state, err) => {
        let errors = err.errors ? err.errors : {};
        if(err.error == "invalid_credentials") {
            errors.invalid_credentials = ['The user credentials were incorrect.'];
        }

        state.status = 'error';
        state.hasLoadedOnce = true;
        state.errors.record(errors);
    },
    authLogout: (state) => {
        state.access_token = '';
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
}