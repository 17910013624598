<template>
    <v-container>
        <form v-on:submit.prevent="onSubmit">
            <v-alert 
                type="warning"
                dense
                outlined
                v-if="(corpusRequired && !params.corpus) || (tagRequired && params.q.length == 0)"
            >
                Veuillez renseigner un corpus et un mot-clé à rechercher
            </v-alert>
            <v-row dense>
                <v-col md="6" cols="12">
                    <v-select 
                        v-model="params.corpus"
                        item-text="name"
                        item-value="id"
                        :items="corpuses"
                        label="Corpus"
                        chips
                        small-chips
                        clearable
                    >
                        <template slot="selection" slot-scope="data">
                            <v-chip small color="secondary">
                                {{ data.item.name }}
                            </v-chip>
                        </template>
                    </v-select>
                </v-col>
                <v-col md="6" cols="12">
                    <v-combobox
                        v-model="params.q"
                        :items="tagsFiltered"
                        label="Mot-clé"
                        chips
                        small-chips
                        deletable-chips
                        multiple
                        :menu-props="{closeOnClick:true,closeOnContentClick:true}"
                    >
                        <template v-slot:selection="{ item, index }">
                            <v-chip small color="primary" close @click:close="params.q.splice(index, 1)">
                                {{ typeof item == 'object' ? item.name : item }}
                            </v-chip>
                        </template>
                    </v-combobox>
                </v-col>
            </v-row>
            <v-row dense v-if="showDatesFilter">
                <v-col md="4" cols="12">
                    <v-menu
                        v-model="dateStartMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="params.dateStart"
                                label="Début"
                                prepend-icon="event"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="params.dateStart"
                            @input="dateStartMenu = false"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col md="4" cols="12">
                    <v-menu
                        v-model="dateEndMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="params.dateEnd"
                                label="Fin"
                                prepend-icon="event"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="params.dateEnd"
                            @input="dateEndMenu = false"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col md="4" cols="12">
                    <v-select 
                        label="Intervalle"
                        :items="intervalChooser"
                        item-text="label"
                        item-value="value"
                        v-model="params.interval"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col md="6" cols="12" v-if="showReadFilter">
                    <v-switch label="Afficher les résultats déjà vus" v-model="params.read"  class="mt-0" hide-details></v-switch>
                </v-col>
                <v-col md="6" cols="12" class="text-right" v-if="showActions">
                    <v-menu offset-y left>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" depressed>
                                Actions
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="readAll">
                                <v-list-item-title>Tout marquer comme lu</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-col>
            </v-row>
        </form>
    </v-container>
</template>

<script>
export default {
    props: {
        value: Object,
        dashboardId: [Number , String],
        corpusRequired: {type: Boolean, default: true},
        tagRequired: {type: Boolean, default: true},
        showActions: {type: Boolean, default: true},
        showReadFilter: {type: Boolean, default: true},
        mode: {type: String, default: 'OR'},
        showDatesFilter: {type: Boolean, default: false},
    },
    data () {
        return {
            ready: false,
            corpuses: [],
            availableTags: [],
            params: {
                corpus: parseInt(this.value.corpus),
                q: this.value.q ? [this.value.q] : [],
                read: this.value.read,
            },
            dateStartMenu: false,
            dateEndMenu: false,
            intervalChooser: [
                {value: 'day', label: 'Jour'},
                {value: 'week', label: 'Semaine'},
                {value: 'month', label: 'Mois'},
                {value: 'year', label: 'Année'},
            ],
        }
    },
    watch: {
        params: {
            deep: true,
            handler() {
                this.setStore()
                this.search()
            }
        }
    },
    computed: {
        tagsFiltered(){
            return this.availableTags.filter( row => row.corpus_id == this.params.corpus ).map( row => row.name )
        }
    },
    mounted () {
        this.setStore()

        this.getCorpuses().then(result => {
            this.corpuses = result.results
            this.getTags().then(result => {
                this.availableTags = result
                //console.log('mounted',this.availableTags)
                this.ready = true
            })
        })
    },
    methods: {
        getCorpuses () {
            return this.$store.dispatch('corpusesRequest', { sort: 'name', direction: 'asc' })
        },
        getTags() {
            return this.$store.dispatch('tagsRequest', { dashboard_id: this.dashboardId, sort: 'name', direction: 'asc' })
        },
        search () {
            let res = this.params
            res['q'] = this.mode == 'OR ' ? this.params.q.join(' OR ') : this.params.q
            if( this.showReadFilter ){
                res['read'] = this.params.read ? 1 : 0
            }
            this.$emit('input', res)
        },
        readAll() {
            this.$emit('readAll', {})
        },
        setStore(){
            this.$store.commit('setCorpus', this.params.corpus)
            this.$store.commit('setQuery', this.params.q.join(' OR '))
        }
        
    }
}
</script>
