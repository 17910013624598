<template>
    <div>
        <v-main>
            <v-container fluid fill-height>
                <v-layout justify-center>
                    <v-flex xs12 sm8 md4>
                        <password-reset-form />
                    </v-flex>
                </v-layout>
            </v-container>
        </v-main>
    </div>
</template>

<script>
import PasswordResetForm from '@/components/forms/PasswordResetForm'

export default {
  components: {
    PasswordResetForm
  }
}
</script>
