<template>
    <v-card v-if="dashboardReady">
        <v-toolbar dark flat color="primary">
            <v-toolbar-title><span class="overline">[Tableau de bord]</span> {{ dashboard.name }} -
                Combinaisons</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-dialog v-model="dialogCorpusTagForm" max-width="800px">
                    <template v-slot:activator="{ on }">
                        <v-btn color="secondary" depressed v-on="on" @click.native="dialogCorpusTagForm = true"
                            class="mr-2">
                            <v-icon>add</v-icon> Ajouter
                        </v-btn>
                    </template>
                    <TagForm :corpuses="corpuses" :dashboard="dashboard" @submit="onSubmit"></TagForm>
                </v-dialog>
                <v-list-item-action>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" @click.native.stop.prevent>more_vert</v-icon>
                        </template>
                        <v-list>
                            <v-list-item>
                                <v-list-item-title style="cursor: pointer"
                                    @click="dashboardDialog = true">Modifier</v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title style="cursor: pointer"
                                    @click="deleteDashboard(dashboard)">Supprimer</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-list-item-action>
                <v-dialog v-model="dashboardDialog" max-width="800px">
                    <dashboard-form ref="DashboardEditForm" v-model="dashboard" action="update"
                        @success="dashboardCreatedOrUpdated" @cancel="dashboardFormCancel" />
                </v-dialog>
            </v-toolbar-items>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn-toggle v-model="displayMode" tile color="secondary" group>
                <v-btn value="corpus">Par corpus</v-btn>
                <v-btn value="tag">Par mot-clé</v-btn>
            </v-btn-toggle>

            <v-spacer></v-spacer>
        </v-card-actions>

        <div>
            <v-divider></v-divider>
            <v-card-actions v-if="displayMode == 'corpus'">
                <v-switch v-model="dashboard.display_mode" true-value="corpus" false-value="tag"
                    label="Enregistrer comme vue par défaut"></v-switch>
            </v-card-actions>
            <v-card-actions v-if="displayMode == 'tag'">
                <v-switch v-model="dashboard.display_mode" true-value="tag" false-value="corpus"
                    label="Enregistrer comme vue par défaut"></v-switch>
            </v-card-actions>
        </div>

        <v-divider class="mb-3"></v-divider>
        <v-card-text>
            <Loader v-if="!ready" />
            <v-window v-model="displayMode" v-if="ready">
                <v-window-item value="corpus">
                    <draggable v-model="tagsByCorpuses" handle=".handle" @end="refreshDashboardOptions">
                        <div v-for="(tagsByCorpus, index) in tagsByCorpuses" :key="tagsByCorpus.corpus.id" class="mb-3">
                            <DashboardCorpusCard v-model="tagsByCorpuses[index]" @sort="refreshDashboardOptions"
                                @clickCombination="clickCombination" />
                        </div>
                    </draggable>
                </v-window-item>
                <v-window-item value="tag">
                    <draggable v-model="corpusesByTags" handle=".handle" @end="refreshDashboardOptions">
                        <div v-for="(corpusesByTag, index) in corpusesByTags" :key="corpusesByTag.tag.id" class="mb-3">
                            <TagCard v-model="corpusesByTags[index]" @sort="refreshDashboardOptions"
                                @clickCombination="clickCombination" />
                        </div>
                    </draggable>
                </v-window-item>
            </v-window>
        </v-card-text>
        <v-dialog v-model="searchDialog">
            <search-results-card :key="searchCorpusId + '/' + searchQuery" :dashboard-id="dashboard.id"
                :corpus-id="searchCorpusId" :query="searchQuery" @close="searchDialog = false" />
        </v-dialog>
    </v-card>
</template>

<script>
import draggable from 'vuedraggable'
import TagForm from '@/components/forms/TagForm'
import DashboardCorpusCard from '@/components/DashboardCorpusCard'
import TagCard from '@/components/TagCard'
import DashboardForm from '@/components/forms/DashboardForm'
import SearchResultsCard from '@/components/SearchResultsCard'
export default {
    components: {
        draggable,
        TagForm,
        DashboardCorpusCard,
        TagCard,
        DashboardForm,
        SearchResultsCard
    },
    props: {
        id: {
            type: [Number, String],
            required: true,
        },
    },
    watch: {
        id() {
            this.initialize()
        },
        'dashboard.display_mode': function (newVal, oldVal) {
            if (oldVal && oldVal !== newVal) {
                this.$store.dispatch('dashboardEditRequest', { id: this.dashboard.id, datas: this.dashboard }).then(() => {
                    this.$store.commit('setSnack', { 'type': 'success', 'msg': `Tableau de bord modifié` })
                    this.$store.dispatch('profileRequest')
                })
            }

        }
    },
    data() {
        return {
            ready: false,
            dashboardReady: false,
            dashboard: null,
            dialogCorpusTagForm: false,
            corpuses: [],
            tags: [],
            tagsByCorpuses: [],
            corpusesByTags: [],
            displayMode: "corpus",
            editedDashboard: {},
            dashboardDialog: false,
            searchDialog: false,
            searchCorpusId: null,
            searchQuery: ''
        }
    },
    mounted() {
        this.initialize()
    },
    methods: {
        initialize() {
            this.dashboardReady = false
            this.ready = false
            this.getDashboard().then(result => {
                this.dashboard = result
                this.dashboardReady = true
                this.displayMode = this.dashboard.display_mode
                this.$store.commit('setDashboard', this.dashboard)

                this.getCorpuses().then(result => {
                    this.corpuses = result.results
                    this.getTags({ dashboard_id: this.dashboard.id }).then(result => {
                        this.tags = result //not paginable
                        this.formatTagsAndCorpuses()
                        this.ready = true
                    })
                })
            }).catch(err => {
                if (err.response.status == 403) {
                    this.$store.commit('setSnack', { 'type': 'error', 'msg': 'Vous n\'êtes pas autorisé à accéder à cette resource !' })
                }
            })
        },
        getDashboard() {
            return this.$store.dispatch('dashboardRequest', { id: this.id })
        },
        getCorpuses() {
            return this.$store.dispatch('corpusesRequest', { sort: 'name', direction: 'asc' })
        },
        getTags(query) {
            return this.$store.dispatch('tagsRequest', query)
        },
        formatTagsAndCorpuses() {
            this.tagsByCorpuses = []
            this.corpusesByTags = []
            let tagsByCorpuses = []
            let corpusesByTags = []
            for (let k in this.tags) {
                let tag = this.tags[k]
                let corpus = this.corpuses.find(corpus => corpus.id == tag.corpus_id)
                //build tagsByCorpus array
                if (corpus) {
                    let existingCorpus = tagsByCorpuses.filter(row => row.corpus.id == corpus.id)
                    if (existingCorpus.length == 0) {
                        let newCorpus = {
                            corpus: corpus,
                            tags: []
                        }
                        tagsByCorpuses.push(newCorpus)
                    }
                    tagsByCorpuses
                        .filter(row => row.corpus.id == corpus.id)
                        .map(row => row.tags.push(tag))


                    //build corpusesByTags array
                    let existingTags = corpusesByTags.filter(row => row.tag.name == tag.name)
                    if (existingTags.length == 0) {
                        let newTag = {
                            tag: tag,
                            corpuses: []
                        }
                        corpusesByTags.push(newTag)
                    }
                    corpusesByTags
                        .filter(row => row.tag.name == tag.name)
                        .map(row => row.corpuses.push(corpus))
                }
            }

            //sort from options
            let dashboardOptions = this.dashboard.options
            if (dashboardOptions) {
                //sort corpuses
                tagsByCorpuses = tagsByCorpuses.sort((a, b) => {
                    let aIndex = dashboardOptions.by_corpus.findIndex(x => x.corpus == a.corpus.id)
                    let bIndex = dashboardOptions.by_corpus.findIndex(x => x.corpus == b.corpus.id)
                    return aIndex > bIndex ? 1 : -1
                })
                //sort tags inside corpuses
                tagsByCorpuses = tagsByCorpuses.map(tagsByCorpus => {
                    let corpusIndex = dashboardOptions.by_corpus.findIndex(x => x.corpus == tagsByCorpus.corpus.id)
                    if (corpusIndex >= 0) {
                        tagsByCorpus.tags.sort((a, b) => {
                            let aIndex = dashboardOptions.by_corpus[corpusIndex].tags.findIndex(x => x == a.name)
                            let bIndex = dashboardOptions.by_corpus[corpusIndex].tags.findIndex(x => x == b.name)
                            return aIndex > bIndex ? 1 : -1
                        })
                    }
                    return tagsByCorpus
                })

                //sort tags
                corpusesByTags = corpusesByTags.sort((a, b) => {
                    let aIndex = dashboardOptions.by_tags.findIndex(x => x.tag == a.tag.name)
                    let bIndex = dashboardOptions.by_tags.findIndex(x => x.tag == b.tag.name)
                    return aIndex > bIndex ? 1 : -1
                })
                //sort corpuses inside tags
                corpusesByTags = corpusesByTags.map(corpusesByTag => {
                    let tagIndex = dashboardOptions.by_tags.findIndex(x => x.tag == corpusesByTag.tag.name)
                    if (tagIndex >= 0) {
                        corpusesByTag.corpuses.sort((a, b) => {
                            let aIndex = dashboardOptions.by_tags[tagIndex].corpuses.findIndex(x => x == a.id)
                            let bIndex = dashboardOptions.by_tags[tagIndex].corpuses.findIndex(x => x == b.id)
                            return aIndex > bIndex ? 1 : -1
                        })
                    }
                    return corpusesByTag
                })
            }
            this.tagsByCorpuses = tagsByCorpuses
            this.corpusesByTags = corpusesByTags

        },
        onSubmit() {
            this.dialogCorpusTagForm = false
            this.ready = false
            this.getTags({ dashboard_id: this.dashboard.id }).then(result => {
                this.tags = result
                this.formatTagsAndCorpuses()
                this.ready = true
            })
        },
        refreshDashboardOptions() {
            //format component json to api format [{corpus:*,tags:[*,*,*]}]
            let byCorpus = this.tagsByCorpuses.map(val => {
                return {
                    corpus: val.corpus.id,
                    tags: val.tags.map(val => val.name)
                }
            });
            let byTags = this.corpusesByTags.map(val => {
                return {
                    tag: val.tag.name,
                    corpuses: val.corpuses.map(val => val.id)
                }
            });
            //
            this.$store.dispatch('dashboardEditRequest', {
                id: this.dashboard.id,
                datas: {
                    options: { by_corpus: byCorpus, by_tags: byTags }
                }
            }).then(() => {
                this.$store.dispatch('profileRequest')
            })
        },
        dashboardCreatedOrUpdated() {
            this.dashboardDialog = false
            this.initialize()
        },
        dashboardFormCancel() {
            console.log('dashboardFormCancel')
            this.editedDashboard = {}
            this.dashboardDialog = false
        },
        deleteDashboard(dashboard) {
            if (confirm('Êtes-vous sûr de vouloir supprimer ce tableau de bord ?')) {
                this.$store.dispatch('dashboardDeleteRequest', { id: dashboard.id })
                    .then(() => {
                        this.$store.commit('setSnack', { 'type': 'success', 'msg': 'Tableau de bord supprimé !' })
                        this.$emit('deleted')
                    })
                    .catch((err) => {
                        this.$store.commit('setSnack', { 'type': 'red', 'msg': err.response.data });
                    })
            }
        },
        clickCombination(tag, corpus) {
            this.searchCorpusId = corpus.id
            this.searchQuery = tag.name
            this.searchDialog = true

        },
    }
}
</script>