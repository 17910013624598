<template>
  <v-card>
    <v-toolbar dark flat color="primary">
      <v-toolbar-title v-if="ready">
        <v-btn class="mr-3" small :to="{ name: 'admin/corpuses' }" exact depressed>
          <v-icon>arrow_left</v-icon>
          Corpus privés
        </v-btn>
        {{ corpus.name }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items v-if="ready">
        <v-btn color="primary" depressed @click="dialogEdit = true">Modifier</v-btn>
        <v-btn color="primary" depressed @click="dialogAddSource = true">Ajouter une source</v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text v-if="ready">
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>Nom du corpus</v-list-item-subtitle>
            <v-list-item-title>{{ corpus.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>Hérité de</v-list-item-subtitle>
            <v-list-item-title v-if="corpus.parent">{{ corpus.parent.name }}</v-list-item-title>
            <v-list-item-title v-else>Non défini</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-tabs v-model="tab">
        <v-tab key="0">
          Sources personnalisées
        </v-tab>
        <v-tab key="1" :disabled="!corpus.parent">
          Sources héritées
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item key="0">
          <v-data-table :items="corpus.sources" disable-sort
            :headers="[{ text: 'Nom de la source' }, { text: 'Type' }, { text: 'Paramètres' }]">
            <template v-slot:item="props">
              <tr>
                <td data-label="Nom de la source">{{ props.item.name }}</td>
                <td data-label="Type">{{ props.item.type_name }}</td>
                <td data-label="Paramètres" v-if="['rss', 'html'].includes(props.item.type)">URL: {{ props.item.url }}
                </td>
                <td data-label="Paramètres" v-else-if="['google', 'twitter'].includes(props.item.type)">Query: {{ props.item.query }}</td>
                <td v-else></td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item key="1" v-if="corpus.parent">
          <v-data-table :items="corpus.parent.sources" disable-sort
            :headers="[{ text: 'Nom de la source' }, { text: 'Type' }, { text: 'Paramètres' }]">
            <template v-slot:item="props">
              <tr>
                <td data-label="Nom de la source">{{ props.item.name }}</td>
                <td data-label="Type">{{ props.item.type_name }}</td>
                <td data-label="Paramètres" v-if="['rss', 'html'].includes(props.item.type)">URL: {{ props.item.url }}
                </td>
                <td data-label="Paramètres" v-else-if="['google'].includes(props.item.type)">Query: {{ props.item.query }}</td>
                <td v-else></td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>

    <v-card-text v-if="!ready">
      <Loader />
    </v-card-text>

    <v-divider />
    <v-card-actions v-if="ready">
      <v-spacer />
      <v-btn color="error" small outlined depressed @click="deleteCorpus(corpus)">Supprimer le corpus privé</v-btn>
    </v-card-actions>
    <v-dialog v-model="dialogEdit" v-if="ready">
      <corpus-form v-model="corpus" action="update" @success="dialogEdit = false, initialize()"
        @cancel="dialogEdit = false" />
    </v-dialog>
    <v-dialog v-model="dialogAddSource" v-if="ready">
      <source-form @created="dialogAddSource = false, initialize()" @cancel="dialogAddSource = false"
        :corpus-id="corpus.id" />
    </v-dialog>
  </v-card>
</template>

<script>
import CorpusForm from '@/components/forms/CorpusForm'
import SourceForm from '@/components/forms/SourceForm'
export default {
  components: {
    CorpusForm,
    SourceForm
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      ready: false,
      corpus: null,
      tab: 0,
      dialogEdit: false,
      dialogAddSource: false
    }
  },
  mounted() {
    this.initialize()
  },
  methods: {
    initialize() {
      this.ready = false
      this.getCorpus().then(result => {
        this.corpus = result
        this.ready = true
      }).catch(err => {
        if (err.response.status == 403) {
          this.$store.commit('setSnack', { 'type': 'error', 'msg': 'Vous n\'êtes pas autorisé à accéder à cette resource !' })
        }
      })
    },
    getCorpus() {
      return this.$store.dispatch('corpusRequest', { id: this.id, with: ['parent', 'sources', 'parent.sources'] })
    },
    deleteCorpus(corpus) {
      if (confirm('Êtes-vous sûr de vouloir supprimer ce corpus privé ?')) {
        this.$store.dispatch('corpusDeleteRequest', { id: corpus.id })
          .then(() => {
            this.$store.commit('setSnack', { 'type': 'success', 'msg': 'Corpus privé supprimé !' });
            this.$router.push({ name: 'admin/corpuses' })
          })
          .catch((err) => {
            this.$store.commit('setSnack', { 'type': 'red', 'msg': err.response.data.message });
          })
      }
    },
  }
}
</script>
