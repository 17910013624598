<template>
    <v-card>
        <v-toolbar dark flat dense color="secondary">
            <v-icon v-if="!showCorpus" @click="showCorpus = !showCorpus">expand_more</v-icon> 
                <v-icon v-if="showCorpus" @click="showCorpus = !showCorpus">expand_less</v-icon> 
            <v-toolbar-title>
                {{ corpus.name }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon class="handle" style="cursor: grab" small>drag_handle</v-icon> 
        </v-toolbar>
        <v-slide-y-transition>
            <v-list v-show="showCorpus">
                <draggable v-model="value.tags" handle=".handle" @end="$emit('sort')">
                    <div :key="tag.id" v-for="tag in value.tags">
                        <v-list-item 
                            @click="$emit('clickCombination', tag, corpus)"
                        >
                            <v-list-item-action>
                                <v-icon class="handle" style="cursor: grab" small @click.stop="">drag_handle</v-icon>
                            </v-list-item-action>
                            <v-list-item-title>
                                {{ tag.name }}
                            </v-list-item-title>
                            <v-list-item-action>
                                <CounterBadge :corpusId="corpus.id" :tag="tag.name"/>
                            </v-list-item-action>
                            
                            <v-list-item-action>
                                <v-menu offset-y>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" @click.native.stop.prevent>more_vert</v-icon>
                                    </template>
                                    <v-list>
                                        <v-list-item>
                                            <v-list-item-title style="cursor: pointer" @click="deleteTag(tag)">Supprimer</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-list-item-action>
                        </v-list-item>
                        <v-divider/>
                    </div>
                </draggable>
            </v-list>
        </v-slide-y-transition>
    </v-card>
</template>
<script>
import draggable from 'vuedraggable'
import CounterBadge from '@/components/CounterBadge'
export default {
    components: {
        draggable,
        CounterBadge
    },
    props: {
        value: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            showCorpus: true,
            corpus: this.value.corpus,
        }
    },
    methods: {
        deleteTag (tag) {
            if (confirm('Êtes-vous sûr de vouloir supprimer ce tag ?')) {
                this.$store.dispatch('tagDeleteRequest', { id: tag.id })
                    .then(() => {
                        this.$store.commit('setSnack', {'type': 'success', 'msg': 'Tag supprimé !'});
                        //this.$emit('tagDelete')
                        this.value.tags = this.value.tags.filter( row => row.id != tag.id )
                        console.log('tags',this.value.tags)
                    })
                    .catch((err) => {
                        this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data});
                    })
            }
        },
    }
}
</script>