<template>
	<v-card v-if="dashboardReady" :key="dashboardId">
        <v-toolbar dark flat color="primary">
            <v-toolbar-title>
                <span class="overline">[Tableau de bord]</span> {{ dashboard.name }} <span class="overline">/ {{documents.length}} résultat(s) en {{timer/1000}} s.</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn text color="white" @click="$emit('close')">
                <v-icon>close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-actions>
            <SearchForm v-model="searchParams" 
                :dashboard-id="dashboardId"
                @readAll="onReadAll"/>
        </v-card-actions>
        <v-card-text>
            <Loader v-if="!documentsReady"/>
            <div v-if="documentsReady">
                <v-alert v-if="documents.length == 0" type="warning" outlined dense>
                    Aucun résultat correspondant à votre recherche
                </v-alert>
                <v-row>
                    <v-col v-for="(doc,index) in documents" :key="doc.id" cols="12" md="4">
                        <DocumentCard 
                            v-model="documents[index]" 
                            :dashboard-id="dashboardId"
                            :query="query"
                            @onRead="onRead(doc)" 
                            @commented="onCommented"
                            hide-actions/>
                    </v-col>
                </v-row>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import SearchForm from '@/components/forms/SearchForm'
import DocumentCard from '@/components/DocumentCard'
export default {
    components: {
        SearchForm,
        DocumentCard,
    },
    props: {
        dashboardId: { 
            type: [Number , String],
            required: true,
        },
        corpusId: { 
            type: [Number , String],
            required: true,
        },
        query: { 
            type: String,
            required: true,
        }
    },
    data () {
        return {
            documentsReady: true,
            dashboardReady: false,
            dashboard: null,
            documents: [],
            searchParams: {
                read: 0,
                dashboard: this.dashboardId,
                corpus: this.corpusId,
                q: this.query
            },
            timer: 0,
        }
    },
    watch: {
        /*id() {
            console.log('id watch', this.id)
            this.documents = []
            this.dashboardReady = false
            this.getDashboard().then(result => {
                this.dashboard = result
                this.dashboardReady = true
                this.searchParams = {
                    dashboard: this.id,
                    corpus: this.$route.query.corpus,
                    q: this.$route.query.q,
                    read: this.$route.query.read,
                }
            })
            
            
        },*/
        searchParams: {
            deep: true,
            handler() {
                console.log('searchParams watch')
                //this.searchParams = { ...this.searchParams, ...searchParams }
                this.search()
            }
        }
    },
    mounted () {
        this.getDashboard().then(result => {
            this.dashboard = result
            this.dashboardReady = true
            this.search()
        })
    },
    methods: {
        search () {
            if(this.searchParams.corpus && this.searchParams.q){
                this.documentsReady = false
                const start = Date.now()
                this.getDocuments().then(result => {
                    this.documents = result
                    this.documentsReady = true
                    const stop = Date.now()
                    this.timer = stop - start
                })
            }
        },
        getDashboard () {
            return this.$store.dispatch('dashboardRequest', {id: this.dashboardId})
        },
        getDocuments () {
            return this.$store.dispatch('resultsRequest', this.searchParams)
        },
        onRead(doc){
            if(doc.is_read && !this.searchParams.read){
                this.documents = this.documents.filter( row =>  row.id != doc.id )
            }
        },
        onCommented(event){
           this.documents.find( row =>  row.id === event.doc.id ).comment = event.comment;
        },
        onReadAll(){
            //TODO Promise.all()
            for(let k in this.documents){
                let doc = this.documents[k]
                if(!doc.is_read){
                    doc.is_read = 1
                    this.$store.dispatch('documentReadRequest', { id: doc.id, read: 1 }).then(()=>{
                        if(!this.searchParams.read){
                            this.documents = this.documents.filter( row =>  row.id != doc.id )
                        }
                    })
                }
            }
        }
    }
}
</script>
