<template>
    <v-card>
        <v-toolbar dark flat color="primary">
            <v-toolbar-title>{{ user.name }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-dialog v-model="dialogEdit" max-width="800px">
                    <template v-slot:activator="{ on }">
                        <v-btn color="secondary" small depressed v-on="on" @click.native="dialogEdit = true">
                            Modifier
                        </v-btn>
                    </template>
                    <user-form v-model="user" action="update" @success="userUpdated" @cancel="dialogEdit = false"/>
                </v-dialog>
                <v-dialog v-model="dialogPassword" v-if="ready && profile.id == user.id" max-width="800px">
                    <template v-slot:activator="{ on }">
                        <v-btn light small depressed v-on="on" @click.native="dialogPassword = true">
                            Changer mon mot de passe
                        </v-btn>
                    </template>
                    <password-change-form @success="passwordChanged" @cancel="dialogPassword = false"/>
                </v-dialog>
            </v-toolbar-items>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text>
            <v-list>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-subtitle>Nom</v-list-item-subtitle>
                        <v-list-item-title>{{ user.name }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-subtitle>Adresse email</v-list-item-subtitle>
                        <v-list-item-title>{{ user.email }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-subtitle>Rôle</v-list-item-subtitle>
                        <v-list-item-title>{{ user.role_name }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>
                            <v-switch dense readonly flat label="Activé" v-model="user.is_active"></v-switch>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card-text>
    </v-card>
</template>
<script>

import UserForm from '@/components/forms/UserForm'
import PasswordChangeForm from '@/components/forms/PasswordChangeForm'
export default {
    components: {
        UserForm,
        PasswordChangeForm
    },
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            ready: false,
            dialogEdit: false,
            dialogPassword: false,
            checkboxesChecked: true, //trick
        }
    },
    mounted () {
        this.profile = this.$store.getters.getProfile()
        this.ready = true;
    },
    methods: {
        userUpdated(){
            this.dialogEdit = false
        },
        passwordChanged(){
            this.dialogPassword = false
        }
    }
}
</script>