<template>
    <div>
        <v-main>
            <v-container fluid>
                <v-card v-if="ready">
                    <trends-card :id="id" :key="id"/>
                </v-card>
            </v-container>
        </v-main>
    </div>
</template>

<script>
import TrendsCard from '@/components/TrendsCard'
export default {
    components: {
        TrendsCard
    },
    props: {
        id: { 
            type: [Number , String],
            required: true,
        },
    },
    data () {
        return {
            ready: false
        }
    },
    mounted () {
        this.ready = true
    },
    methods: {
        // 
    }
}
</script>
