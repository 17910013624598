import Vue from 'vue'
import './plugins/axios'
import vuetify from './plugins/vuetify'
import Policies from './plugins/policies'
import Chartjs from './plugins/chartjs'
import Vuelidate from 'vuelidate'
import router from './router'
import store from './vuex/store'
import App from './App.vue'

import 'vuetify/dist/vuetify.min.css'
import '@/assets/style.scss'

// -----------------------------------------
//             Global loader
// -----------------------------------------

// to active:
// - uncomment these lines
// - uncomment Loader component in App.vue
// - remove loading in data everywhere
// - replace loading by $loading

// let globalDatas = new Vue({
//     data: { $loading: false }
// });
// Vue.mixin({
//     computed: {
//         $loading: {
//             get: function () { return globalDatas.$data.$loading },
//             set: function (newLoading) { globalDatas.$data.$loading = newLoading; }
//         }
//     }
// })
// -----------------------------------------


import Navigation from './components/Navigation.vue'
Vue.component('Navigation', Navigation);

import Snackbar from './components/Snackbar.vue'
Vue.component('Snackbar', Snackbar);

import Loader from './components/Loader.vue'
Vue.component('Loader', Loader);

import UserCard from './components/UserCard.vue'
Vue.component('UserCard', UserCard);

Vue.use(Vuelidate)
Vue.use(Policies)
Vue.use(Chartjs)

//moment config
const moment = require('moment')
require('moment/locale/fr')
Vue.use(require('vue-moment'), {
    moment
})

import lineClamp from 'vue-line-clamp'
Vue.use(lineClamp)

Vue.config.productionTip = false

new Vue({
	vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
