import Vue from 'vue'
import store from '@/vuex/store'

const state = {
    status: '',
}

const getters = {}

const actions = {
    dashboardRequest: async ({commit}, queries) => {
        let url = `/api/dashboards/${queries.id}`;

        return new Promise((resolve, reject) => {
            commit('dashboardRequest')
            Vue.axios.get(url)
                .then(result => {
                    commit('dashboardSuccess')
                    resolve(result.data)
                })
                .catch(err => {
                    commit('dashboardError');
                    reject(err)
                })
        })
    },
    dashboardEditRequest: async ({commit}, queries) => {
        let url = `/api/dashboards/${queries.id}`;

        return new Promise((resolve, reject) => {
            commit('dashboardRequest')
            Vue.axios.put(url, queries.datas)
                .then(result => {
                    commit('dashboardSuccess')
                    resolve(result.data)
                })
                .catch(err => {
                    commit('dashboardError');
                    reject(err)
                })
        })
    },
    dashboardCreateRequest: async ({commit}, queries) => {
        const url = `/api/dashboards`;

        const client = store.getters.getClient()
        queries['client_id'] = client.id
 
        return new Promise((resolve, reject) => {
            commit('dashboardRequest')
            Vue.axios.post(url, queries)
                .then(result => {
                    commit('dashboardSuccess')
                    resolve(result.data)
                })
                .catch(err => {
                    commit('dashboardError');
                    reject(err)
                })
        })
    },
    dashboardDeleteRequest: async ({commit}, queries) => {
        let url = `/api/dashboards/${queries.id}`;

        return new Promise((resolve, reject) => {
            commit('dashboardRequest')
            Vue.axios.delete(url)
                .then(result => {
                    commit('dashboardSuccess')
                    resolve(result)
                })
                .catch(err => {
                    commit('dashboardError');
                    reject(err)
                })
        })
    },
    dashboardsRequest: async ({commit}, queries) => {
        //remove null values
        queries = Object.entries(queries).reduce((a,[k,v]) => (v ? {...a, [k]:v} : a), {})
        //build query url
        let url = '/api/dashboards?'+Object.entries(queries).map(e => e.join('=')).join('&')

        return new Promise((resolve, reject) => {
            commit('dashboardRequest')
            Vue.axios.get(url)
                .then(result => {
                    commit('dashboardSuccess')
                    resolve(result.data)
                })
                .catch(err => {
                    commit('dashboardError');
                    reject(err)
                })
        })
    },
    dashboardCloneRequest: async ({commit}, queries) => {
        let url = `/api/dashboards/${queries.id}/clone`;

        const client = store.getters.getClient()
        queries['client_id'] = client.id
 
        return new Promise((resolve, reject) => {
            commit('dashboardRequest')
            Vue.axios.post(url, queries)
                .then(result => {
                    commit('dashboardSuccess')
                    resolve(result.data)
                })
                .catch(err => {
                    commit('dashboardError');
                    reject(err)
                })
        })
    },
}



const mutations = {
    dashboardRequest: (state) => {
        state.status = 'loading';
    },
    dashboardSuccess: (state) => {
        state.status = 'success';
    },
    dashboardError: (state) => {
        state.status = 'error';
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}