<template>
    <div>
        <v-main>
            <v-container fluid fill-height>
                <v-layout justify-center>
                    <v-flex xs12 sm8 md4>
                        <login-form />
                    </v-flex>
                </v-layout>
            </v-container>
        </v-main>
    </div>
</template>

<script>
    import LoginForm from '@/components/forms/LoginForm'
    export default {
        components: {
            LoginForm
        }
    }
</script>
