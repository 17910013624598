<template>
  <v-card>
    <v-toolbar dark flat color="primary">
      <v-toolbar-title v-if="ready">
        <v-btn class="mr-3" small :to="{ name: 'admin/sources' }" exact depressed>
          <v-icon>arrow_left</v-icon>
          Sources à approuver
        </v-btn>
        {{ source.name }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items v-if="ready">
        <v-btn color="primary" depressed @click="dialogEdit = true">Modifier</v-btn>
        <v-btn color="success" depressed v-if="source.state == 'draft'" @click="approveSource">Approuver la source</v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text v-if="ready">
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>Nom de la source</v-list-item-subtitle>
            <v-list-item-title>{{ source.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>Status</v-list-item-subtitle>
            <v-list-item-title v-if="source.state == 'draft'"><v-chip color="default">{{ source.state_name
            }}</v-chip></v-list-item-title>
            <v-list-item-title v-if="source.state == 'quarantaine'"><v-chip color="warning">{{ source.state_name
            }}</v-chip></v-list-item-title>
            <v-list-item-title v-if="source.state == 'active'"><v-chip color="success">{{ source.state_name
            }}</v-chip></v-list-item-title>
            <v-list-item-title v-if="source.state == 'desactive'"><v-chip color="error">{{ source.state_name
            }}</v-chip></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>Type de source</v-list-item-subtitle>
            <v-list-item-title>{{ source.type_name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="['rss', 'html'].includes(source.type)">
          <v-list-item-content>
            <v-list-item-subtitle>URL</v-list-item-subtitle>
            <v-list-item-title>{{ source.url }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="['google', 'twitter'].includes(source.type)">
          <v-list-item-content>
            <v-list-item-subtitle>Requête</v-list-item-subtitle>
            <v-list-item-title>{{ source.query }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-tabs v-model="tab">
        <v-tab key="0">
          Corpus
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item key="0">
          <v-data-table :headers="[{ text: 'Nom' }, { text: 'Hérité de' }, { text: 'Public' }, {}]"
            :items="source.corpuses" disable-sort>
            <template v-slot:item="props">
              <tr :style="{ cursor: 'pointer' }">
                <td data-label="Nom">{{ props.item.name }}</td>
                <td data-label="Hérité de">{{ props.item.parent ? props.item.parent.name : '' }}</td>
                <td data-label="Public">
                  <v-icon v-if="props.item.is_private" color="error">clear</v-icon>
                  <v-icon v-else color="success">done</v-icon>
                </td>
                <td class="justify-center text-right">
                  <v-btn depressed small color="success"
                    @click.stop="publishToParent(props.item.id)" v-if="props.item.parent && $canPublishSources(profile)">
                    Publier sur corpus parent
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>

    <v-card-text v-if="!ready">
      <Loader />
    </v-card-text>

    <v-divider />
    <v-card-actions v-if="ready">
      <v-spacer />
      <v-btn color="error" small outlined depressed @click="deleteSource(source)">Supprimer la source</v-btn>
    </v-card-actions>
    <v-dialog v-model="dialogEdit" v-if="ready">
      <source-form v-model="source" action="update" @updated="dialogEdit = false, initialize()"
        @cancel="dialogEdit = false" />
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import SourceForm from '@/components/forms/SourceForm'
export default {
  components: {
    SourceForm
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      ready: false,
      source: null,
      dialogEdit: false,
      tab: 0
    }
  },
  computed: {
    ...mapGetters({
      profile: 'profile'
    })
  },
  mounted() {
    this.initialize()
  },
  methods: {
    initialize() {
      this.ready = false
      this.getSource().then(result => {
        this.source = result
        this.ready = true
      }).catch(err => {
        if (err.response.status == 403) {
          this.$store.commit('setSnack', { 'type': 'error', 'msg': 'Vous n\'êtes pas autorisé à accéder à cette resource !' })
        }
      })
    },
    getSource() {
      return this.$store.dispatch('sourceRequest', { id: this.id })
    },
    deleteSource(source) {
      if (confirm('Êtes-vous sûr de vouloir supprimer cette source ?')) {
        this.$store.dispatch('sourceDeleteRequest', { id: source.id })
          .then(() => {
            this.$store.commit('setSnack', { 'type': 'success', 'msg': 'Source supprimée !' });
            this.$router.push({ name: 'admin/sources' })
          })
          .catch((err) => {
            this.$store.commit('setSnack', { 'type': 'red', 'msg': err.response.data });
          })
      }
    },
    approveSource() {
      const data = {
        id: this.source.id
      }
      this.$store.dispatch('sourceApprovalRequest', data)
        .then(() => {
          this.$store.commit('setSnack', { 'type': 'success', 'msg': 'Votre source a été approuvée' })
          this.initialize()
        })
        .catch(error => {
          this.$store.commit('setSnack', { 'type': 'error', 'msg': error.response.data })
        })
    },
    publishToParent(childId) {
      this.$store.dispatch('sourcePublishToParentRequest', { sourceId: this.source.id, childCorpusId: childId })
        .then(() => {
          this.$store.commit('setSnack', { 'type': 'success', 'msg': 'Votre source a été publiée' })
          this.initialize()
        })
        .catch(error => {
          this.$store.commit('setSnack', { 'type': 'error', 'msg': error.response.data.message })
        })
    }
  }
}
</script>
