<template>
    <v-card>
        <v-toolbar dark flat color="secondary">
            <v-toolbar-title>Mot de passe oublié</v-toolbar-title>
        </v-toolbar>
        <form v-on:submit.prevent="onSubmit">
            <v-card-text>
                <v-text-field
                    v-model="email"
                    name="email"
                    :error-messages="emailErrors"
                    label="E-mail"
                    required
                    @input="$v.email.$touch()"
                    @blur="$v.email.$touch()"
                    ></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" type="submit" depressed block :disabled="loading" :loading="loading">
                    Envoyer
                </v-btn>
            </v-card-actions>
        </form>
    </v-card>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'

export default {
    data () {
        return {
            'email': '',
            'loading': false,
        }
    },
    validations: {
        email: { required, email }
    },
    computed: {
        emailErrors () {
            const errors = []

            if (!this.$v.email.$dirty) {
                return errors
            }

            if (!this.$v.email.email) {
                errors.push('E-mail non valide')
            }
            if (!this.$v.email.required) {
                errors.push('E-mail est obligatoire')
            }

            return errors
        }
    },
    methods: {
        resetPassword: function() {
            const credentials = {
                email: this.email
            }

            this.$store.dispatch('resetPassword', credentials)
                .then(() => {
                    this.$router.push({ name: 'login' })
                })
                .catch(() => {
                    this.loading = false;
                })
        },
        onSubmit () {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.loading = true;
            this.resetPassword();
        }
    }
}
</script>
