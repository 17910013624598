<template>
  <div>
    <v-card>
      <v-toolbar dark flat color="primary">
        <v-toolbar-title>Aperçu de la newsletter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn depressed icon @click="$emit('close')">
          <v-icon>
            close
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <Loader v-if="!ready" />
        <iframe v-if="ready" width="100%" height="500" :src="preview"></iframe>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>

export default {
  components: {
    //
  },
  props: {
    dashboardId: {
      required: true
    },
    newsletterId: {
      required: false
    },
  },
  data: () => ({
    ready: false,
    preview: null
  }),
  computed: {
    //
  },
  mounted() {
    this.getPreview()
  },
  methods: {
    getPreview: function () {
      if (this.newsletterId) {
        return this.$store.dispatch('newsletterPreviewRequest', { dashboardId: this.dashboardId, newsletterId: this.newsletterId }).then(resp => {
          const blob = new Blob([resp.preview], { type: "text/html; charset=utf-8" });
          this.preview = URL.createObjectURL(blob)
          this.ready = true
        })
      } else {
        // PREVIEW NEXT
        return this.$store.dispatch('newsletterPreviewRequest', { dashboardId: this.dashboardId }).then(resp => {
          const blob = new Blob([resp.preview], { type: "text/html; charset=utf-8" });
          this.preview = URL.createObjectURL(blob)
          this.ready = true
        })
      }

    }
  }
}
</script>