<template>
    <div class="navigation">
        <v-app-bar clipped-left flat app dark color="secondary">
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            <v-img
                v-if="$store.getters.getClient().logo"
                class="mx-2"
                :src="'data:image/;base64,'+$store.getters.getClient().logo"
                max-height="30"
                max-width="120"
                contain
            ></v-img>
            <v-toolbar-title v-else>{{$store.getters.getClient().name}}</v-toolbar-title>
            <v-spacer></v-spacer>
                <v-menu v-if="profile">
                    <template v-slot:activator="{ on }">
                        <v-btn text v-on="on">
                            <v-icon class="mr-2">person</v-icon>
                            <span class="hidden-md-and-down">
                                {{ profile.name }}
                            </span>
                            <v-icon>keyboard_arrow_down</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item :to="{ name: 'profile' }">
                            <v-list-item-title>Mes informations</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click.stop="logout">
                            <v-list-item-title>Déconnexion</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
        </v-app-bar>

        <v-navigation-drawer 
            v-model="drawer" 
            clipped 
            app 
            dark 
            color="secondary"
            :style="{paddingBottom:'44px', top: $vuetify.application.top + 'px', zIndex: 4}"
        >
            <v-divider></v-divider>
            <v-list nav dense v-if="profile">
                <v-list-group
                    no-action
                    append-icon=""
                    prepend-icon="home"
                    color="white"
                    :value="true"
                >
                    <template v-slot:activator>
                        <v-list-item-title>Mes tableaux de bord</v-list-item-title>
                    </template>
                    <v-list-group
                        no-action
                        sub-group
                        color="white"
                        :value="isDashboardMenuOpen(dashboard)"
                        v-for="dashboard in profile.dashboards"
                        :key="dashboard.id"
                    >
                        <template v-slot:activator>
                            <v-list-item-title>{{ dashboard.name }}</v-list-item-title>
                        </template>
                        <v-list-item 
                            link
                            :to="{ name: 'dashboard', params: { id: dashboard.id } }"
                            exact
                            class="pl-10"
                        >
                            <v-list-item-action><v-icon>toc</v-icon></v-list-item-action>
                            <v-list-item-title>Combinaisons</v-list-item-title> 
                        </v-list-item>
                        <v-list-item 
                            link
                            :to="{ name: 'bookmark', params: { id: dashboard.id } }"
                            class="pl-10"
                        >
                            <v-list-item-action><v-icon>bookmarks</v-icon></v-list-item-action>
                            <v-list-item-title>Marque-pages</v-list-item-title> 
                        </v-list-item>
                        <v-list-item 
                            v-if="dashboard.is_newsletter"
                            link
                            :to="{ name: 'newsletter', params: { id: dashboard.id } }"
                            class="pl-10"
                        >
                            <v-list-item-action><v-icon>mail</v-icon></v-list-item-action>
                            <v-list-item-title>Newsletter</v-list-item-title> 
                        </v-list-item>
                        <v-list-item 
                            link
                            :to="{ name: 'indices', params: { id: dashboard.id } }"
                            class="pl-10"
                            v-if="$canViewIndices(profile)"
                        >
                            <v-list-item-action><v-icon>speed</v-icon></v-list-item-action>
                            <v-list-item-title>Indicateurs</v-list-item-title> 
                        </v-list-item>
                        <v-list-item 
                            link
                            :to="{ name: 'trends', params: { id: dashboard.id } }"
                            class="pl-10"
                        >
                            <v-list-item-action><v-icon>trending_up</v-icon></v-list-item-action>
                            <v-list-item-title>Tendances <v-chip small color="primary">beta</v-chip></v-list-item-title> 
                        </v-list-item>
                    </v-list-group>

                    <v-list-item :to="{name: 'dashboard-create'}" class="pl-6">
                        <v-list-item-title>Créer un tableau de bord</v-list-item-title>
                        <v-list-item-action><v-icon>add</v-icon></v-list-item-action>
                    </v-list-item>

                    <source-proposal-dialog></source-proposal-dialog>

                </v-list-group>

                <v-divider></v-divider>

                <v-list-group
                    append-icon=""
                    prepend-icon="settings"
                    color="white"
                    :value="true"
                    disabled
                    v-if="$canAdministrate(profile)"
                >
                    <template v-slot:activator>
                        <v-list-item-title>Veilleur</v-list-item-title>
                    </template>

                    <v-list-item :to="{name: 'admin/users'}" v-if="$canListUsers(profile)">
                        <v-list-item-title>Utilisateurs</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{name: 'admin/dashboards'}" v-if="$canListDashboards(profile)">
                        <v-list-item-title>Tableaux de bord</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item :to="{name: 'admin/corpuses'}" v-if="$canManageCorpuses(profile)">
                        <v-list-item-title>Corpus privés</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{name: 'admin/sources'}" v-if="$canManageSources(profile)">
                        <v-list-item-title>Sources à approuver</v-list-item-title>
                    </v-list-item>
                </v-list-group>

                <v-divider></v-divider>
                
            </v-list>
            <v-footer color="secondary" class="justify-center" inset fixed>
                <span class="overline">Made with <v-icon small>favorite</v-icon> in Bordeaux</span>
            </v-footer>

        </v-navigation-drawer>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import SourceProposalDialog from "./SourceProposalDialog";
export default {
    components: {SourceProposalDialog},
    data: () => ({
        drawer: null,
    }),
    computed: {
        ...mapGetters({
            profile: 'profile'
        })
    },
    methods: {
        isDashboardMenuOpen(dashboard){
            const route = this.$route
            let isDashboardRouteChildren = route.matched.some(match => match.name == 'dashboardGroup')
            if( isDashboardRouteChildren ){
                if( route.params && route.params.id == dashboard.id ){
                    return true
                }
                
            }
            return false
        },
        logout(){
            this.$store.dispatch('authLogout')
                .then(()=> {
                    this.$router.push({name:'login'})
                })
        }
    },
}
</script>
