import Vue from 'vue'

const state = {
    status: '',
    documents: [],
}

const getters = {}

const actions = {
    documentReadRequest: async ({commit}, queries) => {
        let url = `/api/documents/${queries.id}/read/${queries.read}`;
        return new Promise((resolve, reject) => {
            commit('documentRequest')
            Vue.axios.put(url)
                .then(result => {
                    commit('documentSuccess', result.data)
                    resolve(result.data)
                })
                .catch(err => {
                    commit('documentError');
                    reject(err)
                })
        })
    },
    documentBookmarkRequest: async ({commit}, queries) => {
        let url = `/api/documents/bookmark`;
        return new Promise((resolve, reject) => {
            commit('documentRequest')
            Vue.axios.put(url, queries)
                .then(result => {
                    commit('documentSuccess', result.data)
                    resolve(result.data)
                })
                .catch(err => {
                    commit('documentError');
                    reject(err)
                })
        })
    },
    commentBookmarkRequest: async ({commit}, params) => {
        let url = `/api/documents/comment`;
        return new Promise((resolve, reject) => {
            commit('documentRequest')
            Vue.axios.post(url, params)
                .then(result => {
                    resolve(result.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

}

const mutations = {
    documentRequest: (state) => {
        state.status = 'loading'
    },
    documentSuccess: (state) => {
        state.status = 'success'
    },
    documentError: (state) => {
        state.status = 'error';
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}