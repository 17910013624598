<template>
    <v-card v-if="ready">
        <v-toolbar dark flat color="secondary">
            <v-toolbar-title>{{ action == "update" ? dashboard.name : "Création d'un nouveau tableau de bord" }}</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-divider/>
        <v-form v-on:submit.prevent="onSubmit" ref="form">

            <v-card-text class="mt-4" v-if="first">
                <v-alert type="info" icon="help" border="left" prominent>
                    Il semblerait que ce soit votre première connexion car aucun tableau de bord n'est relié à votre compte.<br/>
                    Un tableau de bord sert à regrouper vos thématiques de recherche.<br/>
                    Veuillez renseigner les informations ci-dessous afin de créer votre premier tableau de bord.
                </v-alert>
            </v-card-text>

            <v-card-text>
                <v-text-field
                    v-model="dashboard.name"
                    name="name"
                    :error-messages="errors.name"
                    label="Intitulé du tableau de bord"
                    required
                    ></v-text-field>

                <v-autocomplete
                    v-if="!self"
                    v-model="dashboard.user"
                    :items="searchedUsers"
                    :search-input.sync="searchUser"
                    label="Utilisateur"
                    item-text="name"
                    item-value="id"
                    return-object
                    required
                ></v-autocomplete>

                <v-select
                    v-model="dashboard.notifications.frequency"
                    item-text="name"
                    item-value="value"
                    :items="[
                        {name: 'Jamais', value: 'never'}, 
                        {name: 'Quotidien', value: 'daily'}, 
                        {name: 'Personnalisé', value: 'custom'}
                    ]"
                    label="Fréquence de notification"
                    ></v-select>

                <v-row v-if="dashboard.notifications.frequency == 'custom'" wrap>
                    <v-col>
                        <v-checkbox 
                            class="mt-0"
                            v-model="dashboard.notifications.params"
                            value="monday" 
                            multiple
                            label="Lundi"></v-checkbox>
                    </v-col>
                    <v-col>
                        <v-checkbox 
                            class="mt-0"
                            v-model="dashboard.notifications.params"
                            value="tuesday" 
                            multiple
                            label="Mardi"></v-checkbox>
                    </v-col>
                    <v-col>
                        <v-checkbox 
                            class="mt-0"
                            v-model="dashboard.notifications.params"
                            value="wednesday" 
                            multiple
                            label="Mercredi"></v-checkbox>
                    </v-col>
                    <v-col>
                        <v-checkbox 
                            class="mt-0"
                            v-model="dashboard.notifications.params"
                            value="thursday" 
                            multiple
                            label="Jeudi"></v-checkbox>
                    </v-col>
                    <v-col>
                        <v-checkbox 
                            class="mt-0"
                            v-model="dashboard.notifications.params"
                            value="friday" 
                            multiple
                            label="Vendredi"></v-checkbox>
                    </v-col>
                    <v-col>
                        <v-checkbox 
                            class="mt-0"
                            v-model="dashboard.notifications.params" 
                            value="saturday"
                            multiple
                            label="Samedi"></v-checkbox>
                    </v-col>
                    <v-col>
                        <v-checkbox 
                            class="mt-0"
                            v-model="dashboard.notifications.params"
                            value="sunday" 
                            multiple
                            label="Dimanche"></v-checkbox>
                    </v-col>
                </v-row>

                <v-combobox
                    v-model="dashboard.subscribers"
                    name="subscribers"
                    :items="[]"
                    label="Abonnés au tableau de bord"
                    chips
                    small-chips
                    deletable-chips
                    multiple
                    :rules="emailRules"
                >
                    <template v-slot:selection="{ item, index }">
                        <v-chip small color="primary" close @click:close="dashboard.subscribers.splice(index, 1)">
                            {{ item }}
                        </v-chip>
                    </template>
                </v-combobox>

                <v-checkbox 
                    v-model="dashboard.is_private"
                    :error-messages="errors.is_private"
                    label="Je souhaite que mon tableau de bord ne soit pas accessible aux veilleurs de l'application"></v-checkbox>


            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="cancel" depressed>Annuler</v-btn>
                <v-btn color="primary" depressed type="submit" :disabled="loading" :loading="loading">Sauvegarder</v-btn>
            </v-card-actions>
        </v-form>
    </v-card>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
        },
        action: {
            type: String,
            default: 'create'
        },
        self: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            dashboard: null,
            errors: {},
            ready: false,
            loading: false,
            searchUser: null,
            searchedUsers: [],
            emailRules :[
                v => {
                    if (v && v.length > 0) {
                        for (let i = 0; i < v.length; i++) {
                            const mailRegexp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                            if ( !mailRegexp.test(v[i]) ) {
                                return 'Invalid email';
                            }
                        }
                    }
                    return true;
                }
            ]
        }
    },
    computed: {
        profile() {
            return this.$store.getters.getProfile();
        },
        first() {
            return this.profile.dashboards.length == 0;
        },
    },
    mounted() {
        this.initialize()
    },
    watch: {
        searchUser (val) {
            if( !val || ( this.dashboard.user && this.dashboard.user.name == val ) ) return
            this.$store.dispatch('usersRequest', {search: val}).then((response)=>{
                this.searchedUsers = response.results
            })
        },
        value () {
            this.initialize()
        }
    },
    methods: {
        initialize () {
            this.ready = false

            if( !this.value.notifications ){
                this.value.notifications = {
                    frequency: 'never',
                    params: {}
                }
            }

            this.dashboard = Object.assign({}, this.value)

            if( this.dashboard.user ){
                this.searchedUsers.push(this.dashboard.user)
            }

            this.ready = true
        },
        onSubmit () {
            this.loading = true

            if( !this.$refs.form.validate() ){
                this.loading = false
                return
            }

            if( this.dashboard.user ){
                this.dashboard.user_id = this.dashboard.user.id
            }
            if( this.self ){
                this.dashboard.user_id = this.profile.id
            }
            if (this.action === 'update') {
                console.log('dashboardEditRequest', this.dashboard)
                this.$store.dispatch('dashboardEditRequest', {id: this.dashboard.id, datas: this.dashboard}).then(()=>{
                    this.loading = false
                    this.$store.commit('setSnack', {'type': 'success', 'msg': `Tableau de bord modifié`})
                    this.$store.dispatch('profileRequest')
                    this.$emit('success')
                    
                }).catch((errors) => {
                    this.loading = false
                    console.log('errors',errors.response)
                    this.errors = errors.response.data
                    return
                })
            }
            if (this.action === 'create') {
                this.$store.dispatch('dashboardCreateRequest', this.dashboard).then( (response) => {
                    this.loading = false
                    this.$store.commit('setSnack', {'type': 'success', 'msg': `Tableau de bord créé`})
                    this.$store.dispatch('profileRequest')
                    this.$emit('success', response.dashboard)
                }).catch((errors) => {
                    this.loading = false
                    this.errors = errors.response.data
                    return
                })
            }

        },
        cancel () {
            this.$emit('cancel')
        },
    }
}
</script>
