<template>
  <div>
    <v-main>
      <v-container fluid>
        <newsletter-list :dashboard-id="id" :key="id" />
      </v-container>
    </v-main>
  </div>
</template>


<script>
import NewsletterList from '@/components/NewsletterList'
export default {
  components: {
    NewsletterList
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      //
    }
  },
  mounted() {
    //
  },
  methods: {
    // 
  }
}
</script>