import Vue from 'vue';
import axios from "axios";
import store from '../vuex/store'
import router from '../router'

let config = {
    baseURL: process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : 'https://api.siveille.io',
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
    async function(config) {
        const accessToken = store.getters.getToken
        if( accessToken ){
            config.headers.common['Authorization'] = `Bearer ${accessToken}`;
        }
        return config
    },
    function(error) {
        return Promise.reject(error)
    }
);

_axios.interceptors.response.use(
    function(response) {
        return response
    },
    function(error) {
        if (  typeof error.response == 'undefined' ) {
            store.commit('setSnack', {type: 'error', msg: 'Server is down'})
        }
        else if ( error.response.status === 401 && store.getters.isAuthenticated ) {
            //console.log('axios.error', error.response)
            store.dispatch('authLogout').then(()=> {
                router.push({name: 'login'})
            })
        }
        return Promise.reject(error)
    }
);

Plugin.install = function(Vue) {
    Vue.axios = _axios;
    window.axios = _axios;
    Object.defineProperties(Vue.prototype, {
        axios: {
            get() {
                return _axios;
            }
        },
        $axios: {
            get() {
                return _axios;
            }
        },
    });
};

Vue.use(Plugin)

export default Plugin;