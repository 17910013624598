<template>
    <div>
        <v-main>
            <v-container fluid>
                <dashboard-form 
                    v-model="createdDashboard"
                    @success="dashboardCreated" 
                    @cancel="dashboardCanceled"
                    self/>
            </v-container>
        </v-main>
    </div>
</template>

<script>
import DashboardForm from '@/components/forms/DashboardForm'
export default {
    components: {
        DashboardForm
    },
    data () {
        return {
            ready: false,
            createdDashboard: {}
        }
    },
    mounted () {
        this.ready = true
    },
    methods: {
        dashboardCreated(dashboard){
            this.$router.push({name: 'dashboard', params: { id: dashboard.id }})
            
        },
        dashboardCanceled(){
            console.log('dashboardCanceled')
        }
    }
}
</script>
