<template>
        <v-card>
            <v-toolbar dark flat color="primary">
                <v-toolbar-title>Ajouter une combinaison</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <form v-on:submit.prevent="onSubmit">
                    <v-row>
                        <v-col md="5" cols="12">
                            <v-select
                                v-model="corpus"
                                item-text="name"
                                item-value="id"
                                :items="corpuses"
                                label="Corpus"
                                :error-messages="errors.corpus"
                            >
                                <template v-slot:selection="{ item }">
                                    <v-chip small color="secondary">{{ item.name }}</v-chip>
                                </template>
                            </v-select>
                        </v-col>
                        <v-col md="5" cols="12">
                            <v-combobox
                                v-model="tags"
                                label="Mots-clés"
                                :error-messages="errors.tags"
                                multiple
                                chips
                                small-chips
                                hint="Elasticsearch query"
                            >
                                <template v-slot:selection="{ item }">
                                    <v-chip small color="secondary">{{ item }}</v-chip>
                                </template>
                            </v-combobox>
                        </v-col>
                        <v-col shrink align-self="center" md="2" cols="12">
                            <v-btn small block depressed color="primary" @click="onSubmit">
                                <v-icon>add</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </form>
            </v-card-text>
        </v-card>
</template>

<script>
export default {
    props: {
        dashboard: {
            type: Object,
            required: true
        },
        corpuses: Array
    },
    data () {
        return {
            ready: false,
            corpus: null,
            tags: null,
            errors: {}
        }
    },
    mounted () {
        this.ready = true
    },
    methods: {
        async onSubmit() {
            this.errors = {};
            if(this.tags && this.tags.length > 0) {
                const validationPromises = this.tags.map(tag => this.isValidTagForSearchRequest(tag));
                try {
                    const results = await Promise.all(validationPromises);
                    const allTagsValid = results.every(result => result === true);
                    if (allTagsValid) {
                        await this.$store.dispatch('tagCreateRequest', {
                            tags: this.tags,
                            corpus: this.corpus,
                            dashboard: this.dashboard.id
                        });
                        this.corpus = null;
                        this.tags = null;
                        this.$emit('submit');
                    } 
                } catch (error) {
                    let errors = error.response.data;
                    for (let key in errors) {
                        let newKey = key.split('.').shift();
                        errors[newKey] = errors[key];
                    }
                    this.errors = errors;
                }
            }
        },
        isValidTagForSearchRequest(tag) {
            return new Promise((resolve) => {
                this.$store
                    .dispatch('resultsCountRequest', { corpusId: this.corpus, tag: tag })
                    .then(() => resolve(true))
                    .catch((err) => {
                        this.$store.commit('setSnack', { 'type': 'red', 'msg': JSON.parse(err.response.data.message).error.root_cause[0].reason });
                        resolve(false)
                    });
            });
        }
    }
}
</script>
