import Vue from 'vue'

const state = {
    status: '',
}

const getters = {}

const actions = {
    messagesRequest: async ({commit}, queries) => {
        let url = '/api/messages'

        return new Promise((resolve, reject) => {
            commit('messagesRequest')
            Vue.axios.get(url,{ params: queries })
                .then(result => {
                    commit('messageSuccess')
                    resolve(result.data)
                })
                .catch(err => {
                    commit('messageError');
                    reject(err)
                })
        })
    }
}



const mutations = {
    messagesRequest: (state) => {
        state.status = 'loading';
    },
    messageSuccess: (state) => {
        state.status = 'success';
    },
    messageError: (state) => {
        state.status = 'error';
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}