<template>
    <v-card>
        <v-toolbar dark flat color="secondary">
            <v-toolbar-title>Changer mon mot de passe</v-toolbar-title>
        </v-toolbar>
        <form v-on:submit.prevent="submitForm">
            <v-card-text>
                <v-text-field
                    v-model="oldPassword"
                    name="old_password"
                    required
                    :error-messages="errors.old_password"
                    :append-icon="showOldPassword ? 'visibility' : 'visibility_off'"
                    label="Ancien mot de passe"
                    counter
                    @click:append="toggleShowOldPassword"
                    :type="showOldPassword ? 'text' : 'password'"
                ></v-text-field>

                <v-text-field
                    v-model="password"
                    name="password"
                    :error-messages="errors.password"
                    :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                    label="Mot de passe"
                    counter
                    @click:append="toggleShowPassword"
                    :type="showPassword ? 'text' : 'password'"
                ></v-text-field>

                <v-text-field
                    v-model="passwordConfirmation"
                    name="confirm"
                    :error-messages="errors.password_confirmation"
                    :append-icon="showPasswordConfirmation ? 'visibility' : 'visibility_off'"
                    label="Confirmer le mot de passe"
                    counter
                    @click:append="toggleShowConfirm"
                    :type="showPasswordConfirmation ? 'text' : 'password'"
                ></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" type="submit" depressed block :disabled="loading" :loading="loading">
                    Valider
                </v-btn>
            </v-card-actions>
        </form>
    </v-card>
</template>

<script>
export default {
    data () {
        return {
            loading: false,
            oldPassword: '',
            password: '',
            passwordConfirmation: '',
            errors: false,
            showOldPassword: false,
            showPassword: false,
            showPasswordConfirmation: false,
        }
    },
    computed: {
        user() {
            return this.$store.getters.getProfile();
        },
    },
    methods: {
        toggleShowOldPassword () {
            this.showOldPassword = !this.showOldPassword
        },
        toggleShowPassword () {
            this.showPassword = !this.showPassword
        },
        toggleShowConfirm () {
            this.showPasswordConfirmation = !this.showPasswordConfirmation
        },
        submitForm () {
            this.loading = true;
            const datas = {
                old_password: this.oldPassword,
                password: this.password,
                password_confirmation: this.passwordConfirmation
            }

            this.$store.dispatch('userEditRequest', {id: this.user.id, datas: datas})
                .then(() => {
                    this.loading = false
                    this.$store.commit('setSnack', {'type': 'success', 'msg': `Mot de passe modifié !`})
                    this.$emit('success')
                }).catch((errors) => {
                    this.loading = false
                    this.errors = errors.response.data
                })
        },
        cancel () {
            this.$emit('cancel');
        },
    }
}
</script>
