<template>
    <v-card>
        <v-toolbar dark flat :color="value.is_read ? 'grey' : 'secondary'" height="auto" class="py-2">
            <div>
                <v-icon v-if="value.is_bookmarked" color="orange" @click.prevent="bookmark"
                    class="mr-2">bookmark</v-icon>
                <v-icon v-else color="white" @click.prevent="openDashboardSelectionDialog"
                    class="mr-2">bookmark_outline</v-icon>
            </div>
            <v-toolbar-title v-line-clamp="3" :style="{ whiteSpace: 'normal', wordBreak: 'normal' }" class="body-2">
                <a :href="value.link" target="_blank"
                    :style="{ color: '#fff', textDecoration: 'none', wordBreak: 'normal' }">{{ value.title }}</a>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <div>
                <v-icon small v-if="!value.is_read" color="white" @click="toggleRead">visibility_off</v-icon>
                <v-icon small v-else color="white" @click="toggleRead">visibility</v-icon>
            </div>

            <v-dialog v-model="dashboardSelectionDialogOpen" max-width="800px">
                <v-card>
                    <v-toolbar dark flat color="primary">
                        <v-toolbar-title>Ajouter un marque-page personnalisé</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <form @submit.prevent="bookmark">
                            <v-row>
                                <v-col cols="12" v-if="dashboardError">
                                    <v-alert type="error" outlined dense>
                                        {{ dashboardError }}
                                    </v-alert>
                                </v-col>
                                <v-col cols="10">
                                    <v-select v-model="selected" :items="options" :menu-props="{ maxHeight: '400' }"
                                        label="Selectionnez les dashboards" multiple
                                        hint="Ce marque page sera ajouté à tous les dashboards sélectionnés."
                                        persistent-hint>
                                        <template v-slot:selection="{ item }">
                                            <v-chip>
                                                <span>{{ item.text }}</span>
                                            </v-chip>
                                        </template>
                                    </v-select>
                                </v-col>
                                <v-col cols="2">
                                    <v-btn :loading="dashboardsLoading" type="submit" depressed color="secondary">
                                        Ajouter
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </form>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-toolbar>
        <v-img v-if="value.picture_url" height="200" :src="value.picture_url"></v-img>
        <v-divider />
        <v-list dense>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>
                        <div>Le {{ value.published_at | moment('D MMMM Y [à] HH[h]mm') }}</div>
                        <div :style="{ whiteSpace: 'normal' }" v-if="value.author"> par {{ value.author }}</div>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider />
            <v-list-item>
                <v-list-item-content class="d-inline-block text-truncate">
                    <a :href="value.link" target="_blank" style="text-decoration: none">
                        <v-icon small>open_in_new</v-icon> {{ value.link }}
                    </a>
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <v-divider v-if="value.source || (value.bookmark && value.bookmark.query)" />
        <v-card-text v-if="value.source || (value.bookmark && value.bookmark.query)">
            <v-list dense class="py-0">
                <v-list-item v-if="value.source">
                    <v-list-item-content>
                        <v-list-item-title>Source: <v-chip small>{{ value.source.name }}</v-chip></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="value.bookmark && value.bookmark.query">
                    <v-list-item-content>
                        <v-list-item-title>Mot-clés: <v-chip small>{{
                            value.bookmark.query
                        }}</v-chip></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card-text>

        <v-divider v-if="value.abstract" />
        <v-card-text v-html="truncate(value.abstract, 200)" v-if="value.abstract"></v-card-text>
        <v-card-text v-html="truncate(value.description, 200)" v-else-if="value.description"></v-card-text>

        <v-divider v-if="value.is_bookmarked && !hideActions" />
        <v-card-text v-if="value.is_bookmarked && !hideActions">
            <div class="d-flex">
                <v-spacer></v-spacer>
                <v-btn @click.prevent="editBookmarkDialogOpen = true" color="primary" depressed outlined small>
                    <v-icon small class="mr-2">edit</v-icon>
                    <span>Modifier le marque-page</span>
                </v-btn>
            </div>
            <v-dialog v-model="editBookmarkDialogOpen" max-width="800px">
                <BookmarkForm v-model="value" @close="editBookmarkDialogOpen = false" @save="(event) => $emit('onEditBookmark', event)"/>
            </v-dialog>
        </v-card-text>

        <v-divider v-if="value.comment" />
        <v-card-text v-if="value.comment">
            <div class="comment"><b>Note: </b> <span v-html="value.comment"></span></div>
        </v-card-text>

        <v-card-text v-if="value.is_bookmarked && !hideActions">
            <div class="d-flex">
                <v-spacer></v-spacer>
                <v-btn @click.prevent="commentDialogOpen = true" color="primary" depressed outlined small>
                    <v-icon small class="mr-2">comment</v-icon>
                    <span v-if="value.comment"> Modifier votre commentaire</span>
                    <span v-else> Ajouter un commentaire</span>
                </v-btn>
            </div>
            <v-dialog v-model="commentDialogOpen" eager max-width="800px">
                <v-card>

                    <v-toolbar dark flat color="primary">
                        <v-toolbar-title v-if="value.comment">Modifier votre commentaire</v-toolbar-title>
                        <v-toolbar-title v-else>Ajouter un commentaire</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <form @submit.prevent="comment">
                            <v-row>
                                <v-col cols="12" v-if="commentError">
                                    <v-alert type="error" outlined dense>
                                        {{ commentError }}
                                    </v-alert>
                                </v-col>
                                <v-col cols="12">
                                    <div :id="'quillWrapper'+value.id">
                                        <div :id="'quillContainer'+value.id"></div>
                                    </div>
                                </v-col>
                                <v-col cols="12" class="d-flex">
                                    <v-spacer></v-spacer>
                                    <v-btn v-if="value.comment" :loading="commentsLoading" @click="deleteComment"
                                        depressed color="error" class="ml-auto danger mr-4">
                                        Supprimer
                                    </v-btn>
                                    <v-btn v-else :loading="commentsLoading" @click="cancelComment"
                                        depressed color="grey" class="ml-auto danger mr-4">
                                        Annuler
                                    </v-btn>
                                    <v-btn :loading="commentsLoading" type="submit" depressed color="secondary"
                                        class="ml-auto">
                                        Enregistrer
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </form>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-card-text>
    </v-card>
</template>

<script>
import BookmarkForm from '@/components/forms/BookmarkForm.vue';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
export default {
    components: {
        BookmarkForm
    },
    props: {
        value: Object,
        dashboardId: [Number, String],
        query: [String],
        hideActions: { type: Boolean, default: false }
    },

    data() {
        return {
            ready: false,
            dashboardSelectionDialogOpen: false,
            commentDialogOpen: false,
            dashboardsLoading: false,
            commentsLoading: false,
            dashboardError: null,
            commentError: null,
            selected: [],
            commentMsg: this.value.comment || '',
            editBookmarkDialogOpen: false,
            quill: null,
        }
    },
    computed: {
        profile() {
            return this.$store.getters.getProfile();
        },
        searchParams() {
            return this.$store.getters.getSearchParams();
        },
        options() {
            return this.profile.dashboards.map((dashboard) => {
                return {
                    text: dashboard.name,
                    value: dashboard.id
                }
            });
        }
    },
    mounted() {
        this.ready = true;
        this.selected = [this.dashboardId];
        this.setUpQuill()
    },
    methods: {
        setUpQuill(){
            this.quill = new Quill(document.getElementById('quillContainer'+this.value.id), {
                debug: false,
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline','link'],
                    ]
                },
                readOnly: false,
                bounds: document.getElementById('quillWrapper'+this.value.id),
                placeholder: 'Ajoutez votre commentaire ...',
                theme: 'snow',  // or 'bubble'
                }
            );
            this.quill.enable();
            this.quill.root.innerHTML = this.commentMsg;
            this.quill.on("text-change", this.handleTextChange);
        },
        handleTextChange(){
            this.commentMsg = document.getElementById('quillContainer'+this.value.id).querySelector(".ql-editor").innerHTML
        },
        truncate(source, size) {
            return source.length > size ? source.slice(0, size - 1) + "…" : source;
        },
        openDashboardSelectionDialog: function () {
            if (!this.value.is_bookmarked)
                this.dashboardSelectionDialogOpen = true;
        },
        toggleRead: function () {
            this.value.is_read = !this.value.is_read
            this.$store.dispatch('documentReadRequest', { id: this.value.id, read: this.value.is_read ? 1 : 0 })
                .then(() => {
                    this.$emit('onRead', this.value)
                })
        },
        bookmark: function () {
            this.dashboardsLoading = true;
            this.dashboardError = null;
            const dashboardInput = this.value.is_bookmarked ? this.dashboardId : this.selected;
            const bookmarkData = {
                document: this.value.id,
                dashboard: dashboardInput,
            }
            if (this.searchParams.query) {
                bookmarkData['query'] = this.searchParams.query
            }
            this.$store.dispatch('documentBookmarkRequest', bookmarkData)
                .then(() => {
                    if (!this.value.is_bookmarked) {
                        if (this.selected.includes(this.dashboardId))
                            this.value.is_bookmarked = true
                    }
                    else
                        this.value.is_bookmarked = false;

                    this.dashboardSelectionDialogOpen = false;

                }).catch(() => {
                    this.dashboardError = "Impossible de créer le(s) marque-pages"
                }).finally(() => {
                    this.dashboardsLoading = false;
                    this.$emit('onBookmark', { document: this.value })
                })
        },
        deleteComment: function () {
            this.commentDialogOpen = false;
            this.commentMsg = '';
            this.quill.root.innerHTML = this.commentMsg;
            this.comment();
        },
        comment: function () {
            this.commentsLoading = true;
            this.commentError = null;
            this.$store.dispatch('commentBookmarkRequest', { document: this.value.id, dashboard: this.dashboardId, comment: this.commentMsg })
                .then((response) => {
                    this.$emit('commented', { doc: this.value, comment: response.comment })
                    this.commentDialogOpen = false;
                }).catch(() => {
                    this.commentError = "Impossible de créer le commentaire"
                }).finally(() => {
                    this.commentsLoading = false;
                })
        },
        cancelComment: function () {
            this.commentDialogOpen = false
            this.quill.root.innerHTML = '';
        }
    }
}
</script>

<style scoped lang="scss">
.pointer {
    cursor: pointer
}
.comment {
    position: relative;
    white-space: pre-wrap;
    background-color: #efefef;
    padding: 1.125em 1.5em;
    border-radius: 0.5rem;
    border: 1px solid #e3e3e3
}
.comment::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    bottom: 100%;
    left: 1.5em;
    border: .75rem solid transparent;
    border-top: none;

    border-bottom-color: #efefef;
    filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, .1));
}
</style>
