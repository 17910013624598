<template>
    <v-card v-if="dashboardReady" :key="id">
        <v-toolbar dark flat color="primary">
            <v-toolbar-title>
                <span class="overline">[Tableau de bord]</span> {{ dashboard.name }} - Indicateurs
            </v-toolbar-title>
        </v-toolbar>
        <v-card-actions>
            
        </v-card-actions>
        <v-card-text>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="text-h5">
                        Taux d'ouverture des e-mails
                    </v-list-item-title>
                    <v-row>
                        <v-col cols="6">
                            <v-list-item-subtitle>Depuis 15 jours</v-list-item-subtitle>
                            <v-list-item-title class="text-h2">
                                <span v-if="messagesReady">{{ messages15OpenedPercent }}<small>%</small></span>
                                <Loader v-else/>
                            </v-list-item-title>
                        </v-col>
                        <v-col cols="6">
                            <v-list-item-subtitle>Depuis toujours</v-list-item-subtitle>
                            <v-list-item-title class="text-h2">
                                <span v-if="messagesReady">{{ messagesOpenedPercent }}<small>%</small></span>
                                <Loader v-else/>
                            </v-list-item-title>
                        </v-col>
                    </v-row>
                    
                </v-list-item-content>
            </v-list-item>
            <v-divider class="mx-4"></v-divider>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="text-h5">
                        Performances du tableau de bord
                    </v-list-item-title>
                    <v-row>
                        <v-col cols="6">
                            <v-list-item-subtitle>Nombre de combinaisons</v-list-item-subtitle>
                            <v-list-item-title class="text-h2">
                                <span v-if="tagsReady">{{ tags.length }}</span>
                                <Loader v-else/>
                            </v-list-item-title>
                        </v-col>
                        <v-col cols="6">
                            <v-list-item-subtitle>Volume hebdomadaire moyen de résultats</v-list-item-subtitle>
                            <v-list-item-title class="text-h2">
                                <span v-if="docsReady">{{ weeklyDocsAverage }}</span>
                                <Loader v-else/>
                            </v-list-item-title>
                        </v-col>
                    </v-row>
                </v-list-item-content>
            </v-list-item>
            <v-divider class="mx-4"></v-divider>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="text-h5 mb-3">
                        Analyse des marque-pages par combinaison
                    </v-list-item-title>
                    <v-list-item-subtitle>Top 10 des combinaisons ayant générées le plus d'articles en marque-page</v-list-item-subtitle>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-center">
                                        #
                                    </th>
                                    <th class="text-center">
                                        Corpus
                                    </th>
                                    <th class="text-center">
                                        Mot-clé
                                    </th>
                                    <th class="text-center">
                                        Résultats
                                    </th>
                                    <th class="text-center">
                                        Marque-pages
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(tag, index) in tags.slice(0, 10)"
                                    :key="'tag-' + tag.id"
                                >
                                    <td class="text-center">{{ index + 1 }}</td>
                                    <td class="text-center">{{ tag.corpus.name }}</td>
                                    <td class="text-center">{{ tag.name }}</td>
                                    <td class="text-center">{{ tag.document_count }}</td>
                                    <td class="text-center">{{ tag.bookmark_count }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-list-item-content>
            </v-list-item>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    components: {
        //
    },
    props: {
        id: { 
            type: [Number , String],
            required: true,
        },
    },
    data () {
        return {
            dashboardReady: false,
            dashboard: null,
            messagesReady: false,
            messages15OpenedPercent: 0,
            messagesOpenedPercent: 0,
            tagsReady: false,
            tags: [],
            docsReady: false,
            weeklyDocsAverage: 0
        }
    },
    watch: {
        //
    },
    mounted () {
        this.getDashboard().then(result => {
            this.dashboard = result
            this.dashboardReady = true
        })
        this.getMessages().then( messages => {
            if( messages.length > 0 ){
                const messagesOpened = messages.filter( message => message.opened_at )
                this.messagesOpenedPercent = Math.round(messagesOpened.length / messages.length * 100)
                //
                const fifteenDaysago = this.$moment(Date.now()).subtract(15, 'days')
                const messages15 = messages.filter( message => this.$moment(message.created_at) > fifteenDaysago )
                const messages15Opened = messages15.filter( message => message.opened_at )
                this.messages15OpenedPercent = Math.round(messages15Opened.length / messages15.length * 100)
            }
            this.messagesReady = true
        })
        this.getTags().then( tags => {
            this.tags = tags
            
            this.getDocuments().then( esResponses => {
                this.calculateWeeklyDocsAverage(esResponses)
                this.setDocumentCountByTag(esResponses)
            })

            this.getBookmarks().then( esResponses => {
                this.setBookmarkCountByTag(esResponses)
                this.sortTags()
                this.tagsReady = true
            })
        })
    },
    methods: {
        getDashboard () {
            return this.$store.dispatch('dashboardRequest', {id: this.id})
        },
        getMessages () {
            return this.$store.dispatch('messagesRequest', {dashboard_id: this.id})
        },
        getTags () {
            return this.$store.dispatch('tagsRequest',  {dashboard_id: this.id})
        },
        getDocuments () {
            //todo: add minDate parameter (in order to avoid strange results i.e. 1970, etc.)
            let promises = []
            this.tags.forEach( tag => {
                let params = {dashboard: this.id, corpus: tag.corpus_id, q: [tag.name], interval: 'week'}
                promises.push( this.$store.dispatch('resultsAggsRequest', params) )
            } )
            return Promise.all(promises)
        },
        getBookmarks () {
            let promises = []
            this.tags.forEach( tag => {
                let params = {dashboard: this.id, corpus: tag.corpus_id, q: [tag.name], bookmark: 1 }
                promises.push( this.$store.dispatch('resultsRequest', params) )
            } )
            return Promise.all(promises)
        },
        calculateWeeklyDocsAverage (esResponses){
            //esResponses is an array of es results
            let docsCount = 0
            let weeksCount = 0 
            let docsByWeek = []
            esResponses.forEach( esResponse => {
                for (const k of Object.keys(esResponse.aggregations) ) { //keyword => {count, buckets}
                    esResponse.aggregations[k].count.buckets.forEach( bucket => {
                        const weekTimestamp = bucket['key']
                        if( !docsByWeek[weekTimestamp] ){
                            docsByWeek[weekTimestamp] = 0
                        }
                        docsByWeek[weekTimestamp] += bucket.doc_count
                        docsCount+= bucket.doc_count 
                    })
                }
                
            } )
            weeksCount = Object.entries(docsByWeek).length
            this.weeklyDocsAverage = (docsCount / weeksCount).toFixed(2)
            this.docsReady = true
        },
        setDocumentCountByTag (esResponses){
            this.tags.map( tag => {
                const esResponseForTag = esResponses.filter( esResponse => {
                    return Object.keys(esResponse.aggregations).includes(tag.name) && esResponse.corpus == tag.corpus_id
                } )
                tag.document_count = esResponseForTag[0].aggregations[tag.name].doc_count
                return tag
            } )
        },
        setBookmarkCountByTag (esResponses){
            this.tags.map( (tag, index) => {
                const esResponseForTag = esResponses[index]
                tag.bookmark_count = esResponseForTag.length
                return tag
            } )
        },
        sortTags (){
            this.tags = this.tags.sort( (a, b) => b.bookmark_count - a.bookmark_count )
        }
    }
}
</script>
