<template>
  <v-card>
    <v-toolbar dark flat color="primary">
      <v-toolbar-title>Options de la newsletter</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn depressed icon @click="$emit('close')">
        <v-icon>
          close
        </v-icon>
      </v-btn>
    </v-toolbar>
    <v-form v-on:submit.prevent="onSubmit" ref="form" v-if="ready">
      <v-card-text>
        <v-text-field v-model="dashboard.newsletter_options.title" name="title" label="Titre de l'email" required
          :rules="[title => !!title || 'Ce champ est obligatoire']"></v-text-field>

        <v-textarea name="introduction" label="Introduction" v-model="dashboard.newsletter_options.introduction"
          rows="3"></v-textarea>

        <!-- <Loader v-if="!documentsReady" />

        <v-alert outlined type="warning" v-if="documentsReady && newsletter.documents.length == 0">
          Aucun document sélectionné. <br />
          Veuillez sélectionner au moins un document en cliquant sur l'icone <v-icon
            color="warning">bookmark_add</v-icon>
        </v-alert> -->

        <!-- <v-row>
          <v-col v-for="document in documents" :key="document.id" cols="12" md="6">
            <v-card>
              <v-toolbar dark flat
                :color="(newsletter.documents.some(newsletterDocument => newsletterDocument.id == document.id)) ? 'secondary' : 'grey'"
                height="auto" class="py-2">
                <div>
                  <v-icon v-if="newsletter.documents.some(newsletterDocument => newsletterDocument.id == document.id)"
                    color="orange" @click.prevent="removeDocument(document)" class="mr-2">bookmark_remove</v-icon>
                  <v-icon v-else color="white" @click.prevent="addDocument(document)" class="mr-2">bookmark_add</v-icon>
                </div>
                <v-toolbar-title v-line-clamp="3" :style="{ whiteSpace: 'normal', wordBreak: 'normal' }" class="body-2">
                  <a :href="document.link" target="_blank"
                    :style="{ color: '#fff', textDecoration: 'none', wordBreak: 'normal' }">{{ document.title }}</a>
                </v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>

              <v-alert v-if="document.sent_at" type="warning" rounded="0">
                Ce marque page a été envoyé le {{ document.sent_at | moment('D MMMM Y [à] HH[h]mm') }}
              </v-alert>

              <v-img v-if="document.picture_url" height="200" :src="document.picture_url"></v-img>

              <v-divider v-if="document.picture_url" />

              <v-list dense>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      <div>Le {{ document.published_at | moment('D MMMM Y [à] HH[h]mm') }}</div>
                      <div :style="{ whiteSpace: 'normal' }" v-if="document.author"> par {{ document.author }}</div>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-divider />

              <v-card-text v-if="document.comment">
                <div class="comment"><b>Note: </b> {{ document.comment }}</div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row> -->

      </v-card-text>

      <v-card-text>
        <v-select v-model="dashboard.newsletter_options.frequency" item-text="name" item-value="value" :items="[
          { name: 'Jamais', value: 'never' },
          { name: 'Hebdomadaire', value: 'weekly' },
          { name: 'Mensuel', value: 'monthly' }
        ]" label="Fréquence"></v-select>


        <v-row v-if="dashboard.newsletter_options.frequency == 'weekly'" wrap>
          <v-col>
            <v-checkbox class="mt-0" v-model="dashboard.newsletter_options.weekly_params" value="monday"
              label="Lundi"></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox class="mt-0" v-model="dashboard.newsletter_options.weekly_params" value="tuesday"
              label="Mardi"></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox class="mt-0" v-model="dashboard.newsletter_options.weekly_params" value="wednesday"
              label="Mercredi"></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox class="mt-0" v-model="dashboard.newsletter_options.weekly_params" value="thursday"
              label="Jeudi"></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox class="mt-0" v-model="dashboard.newsletter_options.weekly_params" value="friday"
              label="Vendredi"></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox class="mt-0" v-model="dashboard.newsletter_options.weekly_params" value="saturday"
              label="Samedi"></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox class="mt-0" v-model="dashboard.newsletter_options.weekly_params" value="sunday"
              label="Dimanche"></v-checkbox>
          </v-col>
        </v-row>

        <p v-if="dashboard.newsletter_options.frequency == 'monthly'">
          <span>Tous les </span>
          <v-select v-model="dashboard.newsletter_options.monthly_params.occurence" item-text="name" item-value="value"
            :items="[
              { name: 'premiers', value: '1' },
              { name: 'deuxièmes', value: '2' },
              { name: 'troisièmes', value: '3' },
              { name: 'quatrièmes', value: '4' }
            ]" label="Occurence" class="d-inline-block"></v-select>
          <v-select v-model="dashboard.newsletter_options.monthly_params.weekday" item-text="name" item-value="value"
            :items="[
              { name: 'lundi', value: 'monday' },
              { name: 'mardi', value: 'tuesday' },
              { name: 'mercredi', value: 'wednesday' },
              { name: 'jeudi', value: 'thursday' },
              { name: 'vendredi', value: 'friday' },
              { name: 'samedi', value: 'saturday' },
              { name: 'dimanche', value: 'sunday' },
            ]" label="Jour de la semaine" class="d-inline-block"></v-select>
          <span>du mois.</span>
        </p>

      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('close')" depressed>Annuler</v-btn>
        <v-btn color="primary" depressed type="submit" :disabled="loading" :loading="loading">Enregistrer</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
export default {
  components: {
    //
  },
  props: {
    value: {
      required: true
    }
  },
  data: () => ({
    dashboard: {},
    ready: false,
    loading: false,
    defaultOptions: {
      title: '',
      introduction: '',
      frequency: 'never',
      weekly_params: null,
      monthly_params: {
        occurence: '1',
        weekday: 'monday',
      },
    }
  }),
  computed: {
    //
  },
  mounted() {
    this.dashboard = { ...this.value }
    if (!this.dashboard.newsletter_options) {
      this.dashboard.newsletter_options = this.defaultOptions
    }
    this.ready = true
  },
  methods: {
    onSubmit() {
      this.loading = true

      if (!this.$refs.form.validate()) {
        this.loading = false
        return
      }

      this.$store.dispatch('dashboardEditRequest', { id: this.dashboard.id, datas: this.dashboard }).then((result) => {
        this.loading = false
        this.$store.commit('setSnack', { 'type': 'success', 'msg': `Paramètres enregistrés` })
        this.dashboard = result.dashboard
        this.$emit('save', this.dashboard)
        this.$emit('close')
      }).catch((errors) => {
        this.loading = false
        this.errors = errors.response.data
        return
      })

    },
  }
}
</script>