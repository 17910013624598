<template>
  <v-card>
    <v-toolbar dark flat color="primary">
      <v-toolbar-title v-if="action == 'create'">Ajouter une source</v-toolbar-title>
      <v-toolbar-title v-if="action == 'update' && name">{{ name }}</v-toolbar-title>
    </v-toolbar>
    <v-form ref="form" v-model="isFormValid" v-on:submit.prevent="onSubmit">
      <v-card-text>
        <v-alert type="warning" v-if="existingSource">
          <v-row no-gutters>
            <v-col class="grow">
              Une source identique existe déjà.
            </v-col>
            <v-col class="shrink">
              <v-btn color="default" outlined depressed small @click="associateExistingSource">Associer la source
                existante à mon corpus privé</v-btn>
            </v-col>
          </v-row>
        </v-alert>

        <v-text-field label="Intitulé de la source" v-model="name" :rules="[rules.required]" class="mb-3"></v-text-field>
        <v-select label="Type de source" v-model="type" :items="types" :rules="[rules.required]" class="mb-3"></v-select>

        <v-text-field v-model="url" label="URL de la source" class="mb-3" :rules="[rules.required, rules.url]"
          :error-messages="errors.url" v-if="['rss', 'HTML'].includes(type)">
          <template v-slot:append-outer>
            <v-btn :color="isTestValid ? 'success' : 'warning'" small depressed
              :disabled="rules.url(url) !== true || testing" :loading="testing" @click="test">TEST</v-btn>
          </template>
        </v-text-field>

        <v-text-field v-model="query" label="Requête" class="mb-3" :rules="[rules.required]"
          :error-messages="errors.query" v-if="['google', 'twitter'].includes(type)">
          <template v-slot:append-outer>
            <v-btn :color="isTestValid ? 'success' : 'warning'" small depressed
              :disabled="rules.required(query) !== true || testing" :loading="testing" @click="test">TEST</v-btn>
          </template>
        </v-text-field>

        <v-alert type="error" v-if="testError">{{ testError }}</v-alert>
        <v-alert type="success" v-if="testResult && !testError">{{ testResult.length }} documents ont pu être
          identifiés.</v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('cancel')" depressed>Annuler</v-btn>
        <v-btn color="primary" depressed type="submit" :disabled="(type == 'rss' && !isTestValid) || loading"
          :loading="loading">Sauvegarder</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
export default {
  props: {
    corpusId: {
      type: [Number, String],
      required: false,
    },
    value: {
      type: Object,
      required: false,
    },
    action: {
      type: String,
      default: 'create'
    }
  },
  data() {
    return {
      loading: false,
      testing: false,
      isFormValid: false,
      isTestValid: false,
      errors: {},
      testError: null,
      testResult: null,
      name: '',
      type: 'rss',
      url: '',
      query: '',
      types: [
        { text: 'RSS', value: 'rss' },
        { text: 'Google', value: 'google' },
        { text: 'Twitter', value: 'twitter' },
        // { text: 'HTML', value: 'html' }
      ],
      rules: {
        url: (v) => {
          const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
          return !!pattern.test(v) || "URL non valide";
        },
        required: (v) => v.length > 0 || "Ce champ est requis"
      },
      //
      existingSource: null,
    }
  },
  mounted() {
    if (this.value) {
      this.name = this.value.name
      this.type = this.value.type
      this.url = this.value.url
      this.query = this.value.query
    }
  },
  methods: {
    async onSubmit() {
      this.errors = {}
      if (this.$refs.form.validate()) {
        if (this.action == 'create') {
          await this.create()
        }
        if (this.action == 'update') {
          await this.update()
        }
      }
    },

    async create() {
      this.existingSource = null
      // check if source does not already exists
      const existingSources = await this.$store.dispatch('sourcesRequest', { url: this.url, query: this.query, type: this.type }).then(resp => resp.results)
      if (existingSources.length > 0) {
        this.existingSource = existingSources[0]
        return
      }

      let data = this.formatData()
      data.state = 'draft'

      if (this.type == 'rss') {
        data.state = 'active'
      }

      this.$store.dispatch('sourceCreateRequest', data)
        .then(() => {
          this.$refs.form.reset();
          this.$emit('created')
          this.$store.commit('setSnack', { 'type': 'success', 'msg': 'Votre source a été ajoutée' })
        })
        .catch(error => {
          let errors = error.response.data
          for (let key in errors) {
            let newKey = key.split('.').shift()
            errors[newKey] = errors[key]
          }
          this.errors = errors
        })
    },

    async update() {
      let data = this.formatData()
      data.id = this.value.id
      this.$store.dispatch('sourceEditRequest', data)
        .then(() => {
          this.$refs.form.reset();
          this.$emit('updated')
          this.$store.commit('setSnack', { 'type': 'success', 'msg': 'Votre source a été modifiée' })
        })
        .catch(error => {
          let errors = error.response.data
          for (let key in errors) {
            let newKey = key.split('.').shift()
            errors[newKey] = errors[key]
          }
          this.errors = errors
        })
    },

    formatData() {
      let data = {
        name: this.name,
        type: this.type
      }
      if (this.corpusId) {
        data['corpuses'] = [this.corpusId]
      }
      if (['rss', 'HTML'].includes(this.type)) {
        data.url = this.url
      }
      if (['google', 'twitter'].includes(this.type)) {
        data.query = this.query
      }
      return data
    },

    async test() {
      this.testing = true
      this.testError = null
      this.testResult = null
      const testData = {
        type: this.type
      }
      if (['rss', 'HTML'].includes(this.type)) {
        testData.url = this.url
      }
      if (['google', 'twitter'].includes(this.type)) {
        testData.query = this.query
      }
      const preview = await this.$store.dispatch('sourcePreviewRequest', testData)
      if (preview.error) {
        this.isTestValid = false
        this.testError = preview.message
        this.testing = false
        return
      }
      this.isTestValid = true
      this.testing = false
      this.testResult = preview
    },

    async associateExistingSource() {
      const data = { id: this.existingSource.id, datas: { corpusesToAdd: [this.corpusId] } }
      await this.$store.dispatch('sourceEditRequest', data)
      this.$refs.form.reset();
      this.$emit('created')
      this.$store.commit('setSnack', { 'type': 'success', 'msg': 'Votre source a été ajoutée' })
    }
  }
}
</script>
