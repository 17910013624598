<template>
    <v-card v-if="dashboardReady">
        <v-toolbar dark flat color="primary">
            <v-toolbar-title>
                <span class="overline">[Tableau de bord]</span> {{ dashboard.name }} - Marque-pages
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-dialog v-model="showBookmarkDialog" max-width="800px">
                    <template v-slot:activator="{ on }">
                        <v-btn color="secondary" depressed v-on="on" @click.native="showBookmarkDialog = true">
                            <v-icon>add</v-icon> Ajouter
                        </v-btn>
                    </template>
                    <CustomBookmarkForm :dashboard="dashboard" @created="bookmarkCreated" />
                </v-dialog>
            </v-toolbar-items>
        </v-toolbar>
        <v-spacer></v-spacer>

        <v-card-text>
            <div class="d-flex align-center">
                <div>
                    <v-switch label="Activer le mode newsletter"
                        v-if="['superadmin', 'app_admin', 'veilleur'].includes(profile.role)"
                        v-model="dashboard.is_newsletter" @change="updateDashboard" hide-details :false-value="0"
                        :true-value="1" class="mt-0"></v-switch>
                </div>
                <!-- <div v-if="dashboard.is_newsletter" class="ml-3">
                    <v-btn depressed small color="primary" @click="showNewsletterSubscribersDialog = true">Gestion des
                        abonnés</v-btn>
                    <v-dialog v-model="showNewsletterSubscribersDialog" max-width="800px">
                        <NewsletterSubscribers :dashboardId="dashboard.id"
                            @close="showNewsletterSubscribersDialog = false" />
                    </v-dialog>

                    <v-btn depressed small color="primary" @click="showNewsletterDialog = true" class="ml-3">Gestion de
                        la newsletter</v-btn>
                    <v-dialog v-if="showNewsletterDialog" v-model="showNewsletterDialog" max-width="800px">
                        <NewsletterForm :dashboard="dashboard" :documents="documents" @close="showNewsletterDialog = false" />
                    </v-dialog>
                </div> -->
            </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
            <div class="d-flex align-center">
                <div>
                    <v-switch label="Partager publiquement les marque-pages"
                        v-if="['superadmin', 'app_admin', 'veilleur'].includes(profile.role)"
                        v-model="dashboard.is_shared" @change="updateDashboard" hide-details :false-value="0"
                        :true-value="1"></v-switch>
                </div>
            </div>
            <div v-if="dashboard.is_shared && dashboard.link" class="mt-3">
                <a :href="dashboard.link" target="_blank" style="text-decoration: none">
                    <v-icon small>feed</v-icon> {{ dashboard.link }}
                </a>
                <br />
                <a :href="dashboard.link.replace('\/feed\/', '\/web\/') + '?d=' + dashboard.id" target="_blank"
                    style="text-decoration: none">
                    <v-icon small>link</v-icon> {{ dashboard.link.replace('\/feed\/', '\/web\/') + '?d=' +
                            dashboard.id
                    }}
                </a>
            </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <SearchForm v-model="searchParams" :dashboard-id="id" :corpus-required="false" :tag-required="false"
                :show-read-filter="false" :show-actions="false" />
        </v-card-actions>
        <v-divider></v-divider>
        <v-card-text>
            <Loader v-if="!documentsReady" />
            <div v-if="documentsReady">
                <v-alert v-if="documents.length == 0" type="warning">
                    Aucun favoris pour ce tableau de bord
                </v-alert>
                <v-row>
                    <v-col v-for="(doc, index) in documents" :key="doc.id" cols="12" md="4">
                        <DocumentCard v-model="documents[index]" :dashboard-id="id" @onBookmark="onBookmark"
                            @commented="onCommented" @onEditBookmark="onEditBookmark" />
                    </v-col>
                </v-row>
            </div>
        </v-card-text>
        <v-card-actions>

        </v-card-actions>
    </v-card>
</template>


<script>
import DocumentCard from '@/components/DocumentCard'
import SearchForm from '@/components/forms/SearchForm'
import CustomBookmarkForm from "@/components/forms/CustomBookmarkForm";
// import NewsletterForm from "@/components/forms/NewsletterForm";
// import NewsletterSubscribers from "@/components/NewsletterSubscribers";

export default {
    components: {
        CustomBookmarkForm,
        DocumentCard,
        SearchForm,
        // NewsletterForm,
        // NewsletterSubscribers
    },
    props: {
        id: [Number, String]
    },
    data() {
        return {
            profile: this.$store.getters.getProfile(),
            dashboardReady: false,
            showBookmarkDialog: false,
            documentsReady: false,
            dashboard: null,
            documents: [],
            searchParams: {
                read: 1,
                bookmark: 1,
                corpus: null,
                dashboard: this.id,
                q: null,
            },
            // showNewsletterSubscribersDialog: false,
            // showNewsletterDialog: false
        }
    },
    watch: {
        searchParams: {
            deep: true,
            handler() {
                this.search()
            }
        }
    },
    mounted() {
        this.loadDashboard()
    },
    methods: {
        bookmarkCreated() {
            this.showBookmarkDialog = false;
            this.search();
        },
        loadDashboard() {
            this.getDashboard().then(result => {
                this.dashboard = result
                this.dashboardReady = true
                this.search()
            })
        },
        search() {
            this.documentsReady = false
            const start = Date.now()
            this.getDocuments().then(result => {
                // set custom data from bookmark if any
                this.documents = result.map( document => {
                    if( document.bookmark && document.bookmark.custom_data ){
                        document = { ...document, ...document.bookmark.custom_data }
                    }
                    return document
                } )
                this.documentsReady = true
                const stop = Date.now()
                this.timer = stop - start
            })
        },
        getDashboard() {
            return this.$store.dispatch('dashboardRequest', { id: this.id })
        },
        getDocuments() {
            //remove null values
            let queries = Object.entries(this.searchParams).reduce((a, [k, v]) => (v ? { ...a, [k]: v } : a), {})
            return this.$store.dispatch('resultsRequest', queries)
        },
        updateDashboard() {
            this.$store.dispatch('dashboardEditRequest', { id: this.dashboard.id, datas: this.dashboard }).then((res) => {
                this.dashboard = res.dashboard
                this.$store.commit('setSnack', { 'type': 'success', 'msg': `Tableau de bord modifié` })
                this.$store.dispatch('profileRequest')
                this.$emit('change', this.dashboard)
            })
        },
        onCommented(event) {
            this.documents.find(row => row.id === event.doc.id).comment = event.comment;
        },
        onBookmark(event) {
            if (!event.document.is_bookmarked) {
                this.documents = this.documents.filter(row => row.id != event.document.id);
            }
        },
        onEditBookmark() {
            this.search()
        },
    }
}
</script>
