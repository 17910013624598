<template>
    <v-card>
        <v-toolbar dark flat color="secondary">
            <v-toolbar-title>Changer de mot de passe</v-toolbar-title>
        </v-toolbar>
        <form v-on:submit.prevent="onSubmit">
            <v-card-text>
                <v-text-field
                    v-model="email"
                    name="email"
                    :error-messages="emailErrors"
                    label="E-mail"
                    required
                    @input="$v.email.$touch()"
                    @blur="$v.email.$touch()"
                ></v-text-field>
                <v-text-field
                    v-model="password"
                    name="password"
                    :error-messages="passwordErrors"
                    :append-icon="show_password ? 'visibility' : 'visibility_off'"
                    label="Mot de passe"
                    required
                    counter
                    @click:append="toggleShowPassword"
                    :type="show_password ? 'text' : 'password'"
                    @input="$v.password.$touch()"
                    @blur="$v.password.$touch()"
                ></v-text-field>
                <v-text-field
                    v-model="confirm"
                    name="confirm"
                    :error-messages="confirmErrors"
                    :append-icon="show_confirm ? 'visibility' : 'visibility_off'"
                    label="Confirmer le mot de passe"
                    required
                    counter
                    @click:append="toggleShowConfirm"
                    :type="show_confirm ? 'text' : 'password'"
                    @input="$v.confirm.$touch()"
                    @blur="$v.confirm.$touch()"
                ></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" type="submit" depressed block :disabled="loading" :loading="loading">
                    Valider
                </v-btn>
            </v-card-actions>
        </form>
    </v-card>
</template>

<script>
import { required, sameAs, email } from 'vuelidate/lib/validators'

export default {
    data () {
        return {
            'email': '',
            'password': '',
            'confirm': '',
            'show_password': false,
            'show_confirm': false,
            'loading': false,
        }
    },
    validations: {
        email: { required, email },
        password: { required },
        confirm: {
            required,
            sameAsPassword: sameAs('password')
        }
    },
    computed: {
        emailErrors () {
            const errors = []

            if (!this.$v.email.$dirty) {
                return errors
            }

            if (!this.$v.email.email) {
                errors.push('E-mail non valide')
            }
            if (!this.$v.email.required) {
                errors.push('E-mail est obligatoire')
            }

            return errors
        },
        passwordErrors () {
            const errors = []
            if (!this.$v.password.$dirty) {
                return errors
            }

            if (!this.$v.password.required) {
                errors.push('Le mot de passe est obligatoire')
            }

            return errors
        },
        confirmErrors () {
            const errors = []
            if (!this.$v.confirm.$dirty) {
                return errors
            }

            if (!this.$v.confirm.sameAsPassword) {
                errors.push('La confirmation et le mot de passe doivent être identique')
            }

            if (!this.$v.confirm.required) {
                errors.push('La confirmation du mot de passe est obligatoire')
            }

            return errors
        }
    },
    methods: {
        toggleShowConfirm () {
            this.show_confirm = !this.show_confirm
        },
        toggleShowPassword () {
            this.show_password = !this.show_password
        },
        editPassword: function () {
            const credentials = {
                email: this.email,
                token: this.$route.params.token,
                password: this.password,
                password_confirmation: this.confirm
            }

            this.$store.dispatch('editPassword', credentials)
                .then(() => {
                    this.$router.push({ name: 'login' })
                })
                .catch(() => {
                    this.loading = false;
                })
        },
        onSubmit () {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.loading = true;
            this.editPassword();
        }
    }
}
</script>
