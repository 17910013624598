import Vue from 'vue'
import store from '@/vuex/store'

const state = {
    status: '',
    corpuses: [],
}

const getters = {}

const actions = {
    corpusRequest: async ({ commit }, queries) => {
        const { id, ...params } = queries
        const url = `/api/corpuses/${id}`;
        return new Promise((resolve, reject) => {
            commit('corpusRequest')
            Vue.axios.get(url, { params })
                .then(result => {
                    commit('corpusSuccess', result.data)
                    resolve(result.data)
                })
                .catch(err => {
                    commit('corpusError');
                    reject(err)
                })
        })
    },
    corpusEditRequest: async ({ commit }, queries) => {
        let url = `/api/corpuses/${queries.id}`;

        return new Promise((resolve, reject) => {
            commit('corpusRequest')
            Vue.axios.put(url, queries.datas)
                .then(result => {
                    commit('corpusSuccess', result.data.corpus)
                    resolve(result.data.corpus)
                })
                .catch(err => {
                    commit('corpusError');
                    reject(err)
                })
        })
    },
    corpusCreateRequest: async ({ commit }, queries) => {
        let url = `/api/corpuses`;

        const client = store.getters.getClient()
        queries['clients'] = [`{"id": ${client.id}}`];

        return new Promise((resolve, reject) => {
            commit('corpusRequest')
            Vue.axios.post(url, queries)
                .then(result => {
                    commit('corpusSuccess', result.data.corpus)
                    resolve(result.data.corpus)
                })
                .catch(err => {
                    commit('corpusError');
                    reject(err)
                })
        })
    },
    corpusDeleteRequest: async ({ commit }, queries) => {
        let url = `/api/corpuses/${queries.id}`;

        return new Promise((resolve, reject) => {
            commit('corpusRequest')
            Vue.axios.delete(url)
                .then(result => {
                    commit('corpusDeleteSuccess')
                    resolve(result)
                })
                .catch(err => {
                    commit('corpusError');
                    reject(err)
                })
        })
    },
    corpusesRequest: async ({ commit }, queries) => {
        let url = '/api/corpuses'
        return new Promise((resolve, reject) => {
            commit('corpusRequest')
            Vue.axios.get(url, { params: queries })
                .then(result => {
                    commit('corpusesSuccess', result.data)
                    resolve(result.data)
                })
                .catch(err => {
                    commit('corpusError');
                    reject(err)
                })
        })
    },
}

const mutations = {
    corpusRequest: (state) => {
        state.status = 'loading';
    },
    corpusSuccess: (state, resp) => {
        state.status = 'success';
        Vue.set(state, 'corpus', resp);
    },
    corpusesSuccess: (state, resp) => {
        state.status = 'success';
        Vue.set(state, 'corpuses', resp);
    },
    corpusDeleteSuccess: (state) => {
        state.status = 'success';
    },
    corpusError: (state) => {
        state.status = 'error';
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}