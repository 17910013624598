<template>
    <v-card>
        <v-toolbar dark flat color="primary">
            <v-toolbar-title>Proposer l'ajout d'une source</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <v-form ref="form" v-model="isFormValid">
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                                v-model="url"
                                name="url"
                                label="URL de la source à ajouter"
                                required
                                class="m-0 p-0"
                                :rules="rules.url"
                                :error-messages="errors.url"
                        />
                    </v-col>
                    <v-col  cols="12">
                        <v-progress-linear v-if="corpuses.length === 0"></v-progress-linear>
                        <v-select
                                v-else
                                v-model="selectedCorpuses"
                                label="Sélectionnez les Corpus qui vous semblent pertinents pour cette source"
                                :error-messages="errors.corpus"
                                :items="corpuses"
                                item-text="name"
                                item-value="id"
                                multiple
                                chips
                                small-chips
                                required
                                :rules="rules.corpus"
                        >
                        </v-select>
                    </v-col>
                    <v-col  align-self="center"  cols="12" class="d-flex">
                        <v-spacer></v-spacer>
                        <v-btn  :loading="sending" small  depressed color="secondary" @click.prevent="onSubmit" class="ml-auto">
                            <v-icon small class="mr-1">send</v-icon> Envoyer votre proposition
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        data () {
            return {
                isFormValid: false,
                ready: false,
                sending: false,
                url: null,
                selectedCorpuses: [],
                corpuses: [],
                tags: null,
                rules: {
                    url: [
                        url => !!url || 'Ce champ est obligatoire',
                        url =>   {
                            const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
                                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
                                '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
                                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
                                '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
                                '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
                            return !!pattern.test(url);
                    }],
                    corpus:[
                        (v) =>  v.length > 0 || "Vous devez sélectionner au moins un Corpus"
                    ]
                },
                errors: {

                }
            }
        },
        mounted () {
            this.$store.dispatch('corpusesRequest', { sort: 'name', direction: 'asc' })
            .then((response) => {
                this.corpuses = response.results;
                this.ready = true;
            })
            .catch(() => {
                this.errors.corpus = "Impossible de charger la liste des corpus"
            });
        },
        methods: {
            onSubmit () {
                if (this.$refs.form.validate()) {
                    this.errors = {}
                    this.sending = true;
                    this.$store.dispatch('sourceProposalRequest', { url: this.url, corpuses: this.selectedCorpuses })
                        .then(() => {
                            this.$refs.form.reset();
                            this.$emit('created')
                             this.$store.commit('setSnack', {'type': 'success', 'msg': 'Votre proposition de source a été transmise'})

                        })
                        .catch(error => {
                            let errors = error.response.data
                            for(let key in errors){
                                let newKey = key.split('.').shift()
                                errors[newKey] = errors[key]
                            }
                            this.errors = errors
                        })
                    .finally(() => {this.sending = false} );
                }
            },

        }
    }
</script>
