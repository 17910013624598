export const mapRoutes = {
    users: 'canListUsers',
    dashboards: 'canListDashboards',
    indices: 'canViewIndices',
    'admin/corpuses': 'canManageCorpuses',
    'admin/corpus': 'canManageCorpuses',
    'admin/sources': 'canManageSources',
    'admin/source': 'canManageSources',
}

export const policies = {
    canAdministrate (user) {
        return user && (user.is_superadmin || user.role == 'veilleur' || user.role == 'app_admin')
    },
    canListUsers (user) {
        return user && (user.is_superadmin || user.role == 'veilleur' || user.role == 'app_admin')
    },
    canListDashboards (user) {
        return user && (user.is_superadmin || user.role == 'veilleur' || user.role == 'app_admin')
    },
    canViewIndices (user) {
        return user && (user.is_superadmin || user.role == 'veilleur' || user.role == 'app_admin')
    },
    canManageCorpuses (user) {
        return user && (user.is_superadmin || user.role == 'veilleur' || user.role == 'app_admin')
    },
    canManageSources (user) {
        return user && (user.is_superadmin || user.role == 'app_admin')
    },
    canPublishSources (user) {
        return user && user.is_superadmin
    }
}

export default {
    install (Vue) {
        Vue.prototype.$canAdministrate = policies['canAdministrate']
        Vue.prototype.$canListUsers = policies['canListUsers']
        Vue.prototype.$canListDashboards = policies['canListDashboards']
        Vue.prototype.$canViewIndices = policies['canViewIndices']
        Vue.prototype.$canManageCorpuses = policies['canManageCorpuses']
        Vue.prototype.$canManageSources = policies['canManageSources']
        Vue.prototype.$canPublishSources = policies['canPublishSources']
    }
};
