<template>
    <div>
        <div class="text-xs-center" v-if="!ready">
            <v-progress-circular
                indeterminate
                size="12"
                width="2"
                color="primary"
            ></v-progress-circular>
        </div>
        <v-avatar size="24" v-if=" ready" color="warning" class="white--text caption">
            <span>{{ result.count }}</span>
        </v-avatar>
    </div>
</template>
<script>
export default {
    props: ['corpusId','tag'],
    data(){
        return {
            results: [],
            ready: false,
        }
    },
    mounted () {
        this.getResultsCount()
    },
    methods: {
        getResultsCount(){
            this.$store.dispatch('resultsCountRequest', { corpusId: this.corpusId, tag: this.tag })
                .then(result => {
                    this.result = result
                    this.ready = true
                })
                .catch(() => {
                    //
                })
        }
    }
}
</script>