<template>
  <v-card>
    <v-toolbar dark flat color="primary">
      <v-toolbar-title>Liste des newsletters</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items v-if="dashboardReady">
        <v-btn color="secondary" depressed @click="showNewsletterSubscribersDialog = true" class="mr-2">
          <v-icon class="mr-2">people</v-icon>
          Gestion des abonnés
        </v-btn>
        <v-dialog v-model="showNewsletterSubscribersDialog" max-width="800px">
          <NewsletterSubscribers :dashboard-id="dashboard.id" @close="showNewsletterSubscribersDialog = false" />
        </v-dialog>

        <!-- <v-btn color="secondary" depressed @click="createItem">Nouvelle newsletter</v-btn>
        <v-dialog v-model="dialogEdit">
          <NewsletterForm :dashboard-id="dashboardId" :editedNewsletter="editedNewsletter" :action="action"
            @success="newsletterCreatedOrUpdated" @close="dialogEdit = false" :key="editedNewsletter.id" />
        </v-dialog>

        <v-btn icon depressed @click="settingsDialog = true">
          <v-icon>settings</v-icon>
        </v-btn>
        <v-dialog v-model="settingsDialog" max-width="800px">
          <NewsletterOptions :dashboard-id="dashboardId" @close="settingsDialog = false"/>
        </v-dialog> -->

        <v-btn color="secondary" depressed @click="settingsDialog = true">
          <v-icon class="mr-2">settings</v-icon>
          Paramètres
        </v-btn>
        <v-dialog v-model="settingsDialog" max-width="800px">
          <NewsletterOptions v-model="dashboard" @close="settingsDialog = false"
            @save="(updatedDashboard) => dashboard = updatedDashboard" />
        </v-dialog>

      </v-toolbar-items>
    </v-toolbar>

    <v-card-text>
      <v-alert type="warning"
        v-if="dashboardReady && (!dashboard.newsletter_options || !dashboard.newsletter_options.title)">
        <div>Vous devez renseigner un titre à votre newsletter.<br />
          Rendez-vous dans les paramètres de vos newsletters </div>
        <div class="mt-2">
          <v-btn small outlined @click="settingsDialog = true">
            <v-icon small class="mr-2">settings</v-icon>
            Paramètres
          </v-btn>
        </div>
      </v-alert>

      <v-alert type="warning"
        v-else-if="dashboardReady && dashboard.newsletter_options && dashboard.newsletter_options.frequency == 'never'">
        Votre newsletter ne sera pas envoyée car sa fréquence est paramétrée sur "jamais"<br />
        Rendez-vous dans les paramètres de vos newsletters pour la modifier.
        <div class="mt-2">
          <v-btn small outlined @click="settingsDialog = true">
            <v-icon small class="mr-2">settings</v-icon>
            Paramètres
          </v-btn>
        </div>
      </v-alert>

      <v-alert type="info" v-else-if="dashboardReady && dashboard.newsletter_next_at">
        <div>Votre newsletter sera envoyée le {{ dashboard.newsletter_next_at.date | moment('dddd D MMMM Y') }}.</div>
        <div class="mt-2">
          <v-btn small outlined @click="previewNextNewsletter()">
            <v-icon small class="mr-2">visibility</v-icon>
            Prévisualiser
          </v-btn>
          <v-btn small outlined @click="sendTest()" class="ml-2">
            <v-icon small class="mr-2">send</v-icon>
            Envoyer sur {{ profile.email }}
          </v-btn>
        </div>
      </v-alert>
    </v-card-text>


    <v-card-text>
      <v-data-table :headers="headers" :items="newsletters" :options.sync="options" :page.sync="options.page"
        :items-per-page.sync="options.itemsPerPage" :server-items-length="total" :multi-sort="false" :loading="loading"
        class="mt-4">
        <template v-slot:item="props">
          <tr>
            <td data-label="Id">{{ props.item.id }}</td>
            <td data-label="Title">{{ props.item.title }}</td>
            <td data-label="CreatedAt" class="text-center">{{ props.item.created_at | moment('D MMMM Y [à] HH[h]mm') }}
            </td>
            <td data-label="Status" class="text-center">
              <v-chip small v-if="props.item.status == 'draft'">Brouillon</v-chip>
              <v-chip small v-if="props.item.status == 'sent'" color="primary">Envoyée le {{
                props.item.sent_at |
                  moment('D MMMM Y [à] HH[h]mm')
              }}</v-chip>
            </td>
            <td class="justify-center text-right">
              <!-- <v-btn depressed icon @click="editItem(props.item)" title="Modifier" v-if="props.item.status == 'draft'">
                <v-icon small>edit</v-icon>
              </v-btn> -->
              <v-btn depressed icon @click="showNewsletter(props.item)" title="Preview">
                <v-icon small>visibility</v-icon>
              </v-btn>
              <!-- <v-btn depressed icon @click="send(props.item)" title="Envoyer" v-if="props.item.status == 'draft'">
                <v-icon small>send</v-icon>
              </v-btn> -->
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-dialog v-model="dialogPreview" max-width="800">
        <NewsletterPreview :dashboard-id="dashboardId" :newsletter-id="previewedNewsletter.id"
          @close="dialogPreview = false" :key="previewedNewsletter.id" v-if="dialogPreview" />
      </v-dialog>
      <!-- <v-dialog v-model="dialogSend" max-width="800">
        <NewsletterSend :dashboard-id="dashboardId" :newsletterId="sentNewsletter.id" @close="dialogSend = false"
          @sent="onSent" :key="sentNewsletter.id" v-if="dialogSend" />
      </v-dialog> -->
    </v-card-text>
  </v-card>
</template>

<script>
// import NewsletterForm from '@/components/forms/NewsletterForm'
import NewsletterSubscribers from "@/components/NewsletterSubscribers"
import NewsletterPreview from "@/components/NewsletterPreview"
// import NewsletterSend from "@/components/NewsletterSend"
import NewsletterOptions from "@/components/forms/NewsletterOptions"

export default {
  name: 'NewsletterList',
  components: {
    // NewsletterForm,
    NewsletterSubscribers,
    NewsletterPreview,
    // NewsletterSend,
    NewsletterOptions
  },
  props: {
    dashboardId: {
      required: true
    }
  },
  data() {
    return {
      profile: this.$store.getters.getProfile(),
      newsletters: [],
      // editedNewsletter: {},
      // shownNewsletter: false,
      // action: 'create',
      options: {
        sortBy: ['created_at'],
        sortDesc: [true]
      },
      total: 0,
      rowsPerPageItems: [10, 20, 40, 100],
      headers: [
        {
          text: 'Id',
          align: 'left',
          value: 'id'
        },
        {
          text: 'Titre',
          align: 'left',
          value: 'title'
        },
        {
          text: 'Créée le',
          align: 'center',
          value: 'created_at'
        },
        {
          text: 'Status',
          align: 'center',
          value: 'status'
        },
        {
          text: '',
          value: '',
          sortable: false
        }
      ],
      // dialogEdit: false,
      loading: false,
      showNewsletterSubscribersDialog: false,
      dialogPreview: false,
      previewedNewsletter: {},
      // dialogSend: false,
      // sentNewsletter: {},
      settingsDialog: false,
      dashboard: {},
      dashboardReady: false,
      sending: false,
    }
  },
  watch: {
    options: {
      handler() {
        this.optionsHandler()
      },
      deep: true
    }
  },
  mounted() {
    this.getDashboard().then(result => {
      this.dashboard = result
      this.dashboardReady = true
    })
  },
  methods: {
    getDashboard() {
      return this.$store.dispatch('dashboardRequest', { id: this.dashboardId })
    },
    optionsHandler() {
      this.loading = true
      this.getNewsletters()
        .then(result => {
          this.newsletters = result.results
          this.total = result.total
          this.loading = false
        })
    },
    getNewsletters() {
      let params = {
        //search: this.search,
        dashboardId: this.dashboardId,
        page: this.options.page,
        per_page: this.options.itemsPerPage,
        sort: this.options.sortBy.length > 0 ? this.options.sortBy[0] : null,
        direction: this.options.sortDesc.length && this.options.sortDesc[0] ? 'desc' : 'asc'
      }
      return this.$store.dispatch('newslettersRequest', params)
    },
    // createItem() {
    //   this.action = 'create'
    //   this.editedNewsletter = {}
    //   this.dialogEdit = true
    // },
    // editItem(newsletter) {
    //   this.action = 'update'
    //   this.editedNewsletter = newsletter
    //   this.dialogEdit = true
    // },
    // newsletterCreatedOrUpdated() {
    //   this.dialogEdit = false
    //   this.editedNewsletter = {}
    //   this.optionsHandler()
    // },
    showNewsletter(newsletter) {
      this.previewedNewsletter = newsletter
      this.dialogPreview = true
    },
    previewNextNewsletter() {
      this.previewedNewsletter = {
        id: null
      }

      this.dialogPreview = true
      // this.previewedNewsletter = newsletter
      // this.dialogPreview = true
    },
    sendTest(){
      this.sending = true
      return this.$store.dispatch('newsletterSendTestRequest', { dashboardId: this.dashboardId }).then( () => {
        this.sending = false
        this.$store.commit('setSnack', { 'type': 'success', 'msg': `Newsletter correctement envoyée` })
        this.$emit('sent')
      }).catch( () => {
        this.sending = false
        this.$store.commit('setSnack', { 'type': 'error', 'msg': 'Votre newsletter n\'a pas pu être coorectement envoyée. Merci de prendre contact avec le support' })
      })
    }
    // send(newsletter) {
    //   this.sentNewsletter = newsletter
    //   this.dialogSend = true
    // },
    // onSent() {
    //   this.dialogSend = false
    //   this.optionsHandler()
    // }
  },
}
</script>
