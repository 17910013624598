<template>
    <div>
        <v-main>
            <v-container fluid>
                <v-card>
                    <v-toolbar dark flat color="primary">
                        <v-toolbar-title>Liste des tableaux de bord</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn color="secondary" depressed @click="createItem">Nouveau tableau de bord</v-btn>
                            <v-dialog v-model="dialogEdit">
                                <dashboard-form 
                                    ref="DashboardEditForm" 
                                    v-model="editedDashboard" 
                                    :action="action" 
                                    :self="false"
                                    @success="dashboardCreatedOrUpdated" 
                                    @cancel="dashboardFormCancel"/>
                            </v-dialog>
                            <v-dialog v-model="dialogShow" :key="shownDashboard.id">
                                <v-tabs v-model="tab" background-color="secondary" dark>
                                    <v-tab key="combinations" id="combinations" href="#combinations">Combinaisons</v-tab>
                                    <v-tab key="bookmarks" id="bookmarks" href="#bookmarks">Marque-pages</v-tab>
                                    <v-tab key="newsletters" id="newsletters" href="#newsletters" v-if="shownDashboard.is_newsletter">Newsletter</v-tab>
                                </v-tabs>
                                <v-tabs-items v-model="tab">
                                    <v-tab-item key="combinations" id="combinations">
                                        <dashboard-card :id="shownDashboard.id"
                                            @deleted="deleteFromCard"
                                        />
                                    </v-tab-item>
                                    <v-tab-item key="bookmarks" id="bookmarks">
                                        <bookmark-card :id="shownDashboard.id" @change="(updatedDashboard) => shownDashboard = updatedDashboard"/>
                                    </v-tab-item>
                                    <v-tab-item key="newsletters" id="newsletters" v-if="shownDashboard.is_newsletter">
                                        <newsletter-list :dashboard-id="shownDashboard.id"/>
                                    </v-tab-item>
                                </v-tabs-items>
                            </v-dialog>
                            <v-dialog v-model="dialogClone" max-width="800px">
                                <dashboard-clone :dashboard="shownDashboard"
                                    @success="dashboardCloned" 
                                    @cancel="dialogClone = false"
                                />
                            </v-dialog>
                            <v-dialog v-model="dialogIndices" max-width="800px">
                                <indices-card :id="shownDashboard.id"
                                    @cancel="dialogIndices = false"
                                />
                            </v-dialog>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-card-text>
                        <v-text-field
                            v-model="search"
                            append-icon="search"
                            label="Rechercher"
                            single-line
                            hide-details
                            ></v-text-field>
                        

                        <v-data-table 
                            :headers="headers"
                            :items="dashboards"
                            :options.sync="options"
                            :page.sync="options.page"
                            :items-per-page.sync="options.itemsPerPage"
                            :server-items-length="total"
                            :multi-sort="false"
                            :loading="loading"
                            class="mt-4"
                        >
                            <template v-slot:item="props">
                                <tr>
                                    <td data-label="Nom">{{ props.item.name }}</td>
                                    <td data-label="User">{{ props.item.user.name }}</td>
                                    <td data-label="Newsletter" class="text-center">
                                        <v-icon small color="success" v-if="props.item.is_newsletter">check</v-icon>
                                        <v-icon small color="error" v-else>close</v-icon>
                                    </td>
                                    <td class="justify-center text-right">
                                        <!--<v-icon small class="mr-2" @click="showItem(props.item)">remove_red_eye</v-icon>-->
                                        <!--<v-btn depressed icon :to="{name: 'admin/dashboard', params: { id: props.item.id }}">
                                            <v-icon small>remove_red_eye</v-icon>
                                        </v-btn>-->
                                        <v-btn depressed icon @click="showItem(props.item)" v-if="!props.item.is_private">
                                            <v-icon small>visibility</v-icon>
                                        </v-btn>
                                        <v-btn depressed icon v-else>
                                            <v-icon small color="error">visibility_off</v-icon>
                                        </v-btn>

                                        <v-btn depressed icon @click="showIndices(props.item)" v-if="!props.item.is_private">
                                            <v-icon small>speed</v-icon>
                                        </v-btn>
                                        <v-btn depressed icon v-else>
                                            <v-icon small color="error">speed</v-icon>
                                        </v-btn>

                                        <v-btn depressed icon @click="editItem(props.item)">
                                            <v-icon small>edit</v-icon>
                                        </v-btn>
                                        <v-btn depressed icon @click="cloneItem(props.item)">
                                            <v-icon small>content_copy</v-icon>
                                        </v-btn>
                                        <v-btn depressed icon @click="deleteItem(props.item)">
                                            <v-icon small>delete</v-icon>
                                        </v-btn>
                                        
                                        
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>

                    </v-card-text>
                </v-card>
            </v-container>
        </v-main>
    </div>
</template>

<script>
import DashboardForm from '@/components/forms/DashboardForm'
import DashboardCard from '@/components/DashboardCard'
import DashboardClone from '@/components/DashboardClone'
import BookmarkCard from '@/components/BookmarkCard'
import IndicesCard from '@/components/IndicesCard'
import NewsletterList from '@/components/NewsletterList'

export default {
    name: 'Dashboards',
    components: {
        DashboardForm,
        DashboardCard,
        DashboardClone,
        BookmarkCard,
        IndicesCard,
        NewsletterList
    },
    data () {
        return {
            dashboards: [],
            editedIndex: -1,
            editedDashboard: {},
            shownDashboard: false,
            action: 'create',
            options: {},
            total: 0,
            rowsPerPageItems: [10, 20, 40, 100],
            headers: [
                {
                    text: 'Intitulé',
                    align: 'left',
                    value: 'name'
                },
                { 
                    text: 'Utilisateur', 
                    align: 'left', 
                    value: 'user.name',
                    sortable: false
                },
                { 
                    text: 'Newsletter', 
                    align: 'center', 
                    value: 'is_newsletter' ,
                    sortable: false
                },
                {
                    text: '',
                    value: '',
                    sortable: false
                }
            ],
            search: '',
            dialogEdit: false,
            dialogShow: false,
            loading: false,
            dialogClone: false,
            tab: null,
            dialogIndices: false,
        }
    },
    watch: {
        options: {
            handler () {
                this.optionsHandler()
            },
            deep: true
        },
        search: {
            handler () {
                this.optionsHandler()
            },
            deep: true
        },
        dialogShow() {
            if(!this.dialogShow){
                this.$router.push({ hash: '', query: {}})
                this.tab = 'combinations'
            }
        }
    },
    methods: {
        optionsHandler(){
            this.loading = true
            this.getDashboards()
                .then(result => {
                    this.dashboards = result.results
                    this.total = result.total
                    this.loading = false
                })
        },
        getDashboards () {
            let params= { 
                search: this.search, 
                page: this.options.page,
                per_page: this.options.itemsPerPage,
                sort: this.options.sortBy.length > 0 ? this.options.sortBy[0] : null,
                direction: this.options.sortDesc.length && this.options.sortDesc[0] ? 'desc' : 'asc'
            }
            return this.$store.dispatch('dashboardsRequest', params)
        },
        showItem (dashboard) {
            this.shownDashboard = dashboard
            this.dialogShow = true
        },
        createItem (){
            console.log('createItem')
            this.action = 'create'
            this.editedDashboard = {}
            this.dialogEdit = true
        },
        editItem (dashboard) {
            this.action = 'update'
            this.editedIndex = this.dashboards.indexOf(dashboard)
            this.editedDashboard = dashboard
            this.dialogEdit = true
        },
        deleteItem (dashboard) {
            if (confirm('Êtes-vous sûr de vouloir supprimer ce tableau de bord ?')) {
                this.$store.dispatch('dashboardDeleteRequest', { id: dashboard.id })
                    .then(() => {
                        this.$store.commit('setSnack', {'type': 'success', 'msg': 'Tableau de bord supprimé !'});
                        this.optionsHandler()
                    })
                    .catch((err) => {
                        this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data});
                    })
            }
        },
        dashboardCreatedOrUpdated(){
            this.dialogEdit = false
            this.editedDashboard = {}
            this.optionsHandler()
        },
        dashboardFormCancel(){
            console.log('dashboardFormCancel')
            this.editedDashboard = {}
            this.dialogEdit = false
        },
        cloneItem (dashboard) {
            this.shownDashboard = dashboard
            this.dialogClone = true
        },
        dashboardCloned() {
            this.dialogClone = false
            this.$store.commit('setSnack', {'type': 'success', 'msg': 'Tableau de bord dupliqué !'});
            this.optionsHandler()
        },
        deleteFromCard() {
            this.shownDashboard = null
            this.dialogShow = false
            this.optionsHandler()
        },
        showIndices (dashboard) {
            this.shownDashboard = dashboard
            this.dialogIndices = true
        },
    },
}
</script>
