<template>
    <v-card>
        <v-toolbar dark flat color="secondary">
            <v-toolbar-title>{{ value.name }}</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <form v-on:submit.prevent="onSubmit" v-if="ready">
            <v-card-text>
                <v-text-field
                    v-model="value.name"
                    name="name"
                    :error-messages="errors.name"
                    label="Nom"
                    required
                    ></v-text-field>

                <v-text-field
                    v-model="value.email"
                    name="email"
                    :error-messages="errors.email"
                    label="E-mail"
                    required
                    ></v-text-field>

                <div v-if="action == 'create'">
                    <v-text-field
                        v-model="value.password"
                        name="password"
                        :error-messages="errors.password"
                        :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                        label="Mot de passe"
                        required
                        counter
                        @click:append="toggleShowPassword"
                        :type="showPassword ? 'text' : 'password'"
                    ></v-text-field>

                    <v-text-field
                        v-model="value.password_confirmation"
                        name="confirm"
                        :error-messages="errors.password_confirmation"
                        :append-icon="showConfirm ? 'visibility' : 'visibility_off'"
                        label="Confirmer le mot de passe"
                        required
                        counter
                        @click:append="toggleShowConfirm"
                        :type="showConfirm ? 'text' : 'password'"
                    ></v-text-field>
                </div>

                <v-switch 
                    class="mt-1"
                    v-model="value.is_active" 
                    name="is_active"
                    :label="value.is_active ? 'Activé' : 'Désactivé'"
                    ></v-switch>

            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click.stop="cancel" depressed>Annuler</v-btn>
                <v-btn color="primary" depressed type="submit" :disabled="loading" :loading="loading">Sauvegarder</v-btn>
            </v-card-actions>
        </form>
    </v-card>
</template>

<script>
export default {
    props: {
        value: Object,
        action: {
            type: String,
            default: 'update'
        }
    },
    data () {
        return {
            errors: {},
            ready: false,
            loading: false,
            //
            showPassword: false,
            showConfirm: false
        }
    },
    mounted() {
        this.ready = true
    },
    methods: {
        toggleShowConfirm () {
            this.showConfirm = !this.showConfirm
        },
        toggleShowPassword () {
            this.showPassword = !this.showPassword
        },
        onSubmit () {
            this.loading = true
            if (this.action === 'update') {
                this.$store.dispatch('userEditRequest', {id: this.value.id, datas: this.value}).then((response)=>{
                    //if user == current user => update store
                    let profile = this.$store.getters.getProfile()
                    if(profile.id === this.value.id){
                        this.$store.commit('profileSuccess', response)
                    }
                    this.loading = false
                    this.$store.commit('setSnack', {'type': 'success', 'msg': `Utilisateur modifié`})
                    this.$emit('success')
                    
                }).catch((errors) => {
                    this.loading = false
                    console.log('errors',errors.response)
                    this.errors = errors.response.data
                })
            }
            if (this.action === 'create') {
                this.$store.dispatch('userCreateRequest', this.value).then(()=>{
                    this.loading = false
                    this.$store.commit('setSnack', {'type': 'success', 'msg': `Utilisateur créé`})
                    this.$emit('success')
                }).catch((errors) => {
                    this.loading = false
                    this.errors = errors.response.data
                })
            }
        },
        cancel () {
            this.$emit('cancel');
        },
    }
}
</script>
