<template>
  <v-card v-if="ready">
    <v-toolbar dark flat color="secondary">
      <v-toolbar-title>{{ action == "update" ? corpus.name : "Création d'un nouveau corpus privé" }}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-divider />
    <v-form v-on:submit.prevent="onSubmit" ref="form">
      <v-card-text>
        <v-text-field v-model="corpus.name" name="name" :error-messages="errors.name" label="Intitulé du corpus privé"
          required></v-text-field>

        <v-autocomplete v-model="corpus.parent" :items="corpuses" label="Hérité de" item-text="name" item-value="id" clearable></v-autocomplete>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="cancel" depressed>Annuler</v-btn>
        <v-btn color="primary" depressed type="submit" :disabled="loading" :loading="loading">Sauvegarder</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
    },
    action: {
      type: String,
      default: 'create'
    }
  },
  data() {
    return {
      corpus: null,
      corpuses: null,
      errors: {},
      ready: false,
      loading: false
    }
  },
  computed: {
    profile() {
      return this.$store.getters.getProfile();
    }
  },
  mounted() {
    this.initialize()
  },
  watch: {
    value() {
      this.initialize()
    }
  },
  methods: {
    async initialize() {
      const client = this.$store.getters.getClient()
      const parent = this.value && this.value.parent ? this.value.parent.id: null
      this.ready = false
      this.corpus = { ...this.value, is_private: true, private_client_id: client.id, clientsToAdd: [client.id], parent: parent }
      this.corpuses = await this.$store.dispatch('corpusesRequest', { sort: 'name', direction: 'asc' }).then( resp => resp.results)
      this.ready = true
    },
    onSubmit() {
      this.loading = true

      if (!this.$refs.form.validate()) {
        this.loading = false
        return
      }

      if (this.action === 'update') {
        
        if( !this.corpus.parent ){
          this.corpus.parent = null
        }
        console.log('corpusEditRequest', this.corpus)
        this.$store.dispatch('corpusEditRequest', { id: this.corpus.id, datas: this.corpus }).then(() => {
          this.loading = false
          this.$store.commit('setSnack', { 'type': 'success', 'msg': `Corpus privé modifié` })
          this.$store.dispatch('profileRequest')
          this.$emit('success')

        }).catch((errors) => {
          this.loading = false
          console.log('errors', errors.response)
          this.errors = errors.response.data
          return
        })
      }
      if (this.action === 'create') {
        this.$store.dispatch('corpusCreateRequest', this.corpus).then((response) => {
          this.loading = false
          this.$store.commit('setSnack', { 'type': 'success', 'msg': `Corpus privé créé` })
          this.$store.dispatch('profileRequest')
          this.$emit('success', response.corpus)
        }).catch((errors) => {
          this.loading = false
          this.errors = errors.response.data
          return
        })
      }

    },
    cancel() {
      this.$emit('cancel')
    }
  }
}
</script>
