<template>
  <div>
    <v-card>
      <v-toolbar dark flat color="primary">
        <v-toolbar-title>Liste des abonnés à la newsletter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn depressed icon @click="$emit('close')">
          <v-icon color="seondary">
            close
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-form v-on:submit.prevent="onSubmit" ref="form">
          <v-text-field v-model="addEmailValue" name="email" hide-details label="E-mail" required :error="errors.email">
            <v-btn depressed icon slot="append" type="submit">
              <v-icon color="primary">
                add
              </v-icon>
            </v-btn>
          </v-text-field>
        </v-form>

        <v-data-table :headers="headers" :items="newsletterSubscribers" :options.sync="options"
          :page.sync="options.page" :items-per-page.sync="options.itemsPerPage" :multi-sort="false" :loading="loading"
          class="mt-4">
          <template v-slot:item="props">
            <tr>
              <td data-label="Email">{{ props.item.email }}</td>
              <td class="justify-end layout">
                <v-btn depressed icon @click="deleteNewsletterSubscriber(props.item.id)">
                  <v-icon small>delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>

      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  components: {
    //
  },
  props: {
    dashboardId: {
      required: true
    },
  },
  data: () => ({
    addEmailValue: '',
    errors: {},
    headers: [
      {
        text: 'Email',
        align: 'left',
        value: 'email'
      },
      {
        text: '',
        value: '',
        sortable: false
      }
    ],
    newsletterSubscribers: [],
    options: {},
    total: 0,
    loading: false
  }),
  computed: {
    //
  },
  mounted() {
    this.getNewsletterSubscribers()
  },
  methods: {
    getNewsletterSubscribers: function () {
      this.loading = true
      this.$store.dispatch('newsletterSubscribersRequest', { id: this.dashboardId }).then(resp => {
        console.log('resp', resp)
        this.newsletterSubscribers = resp
        this.loading = false
      })
    },
    onSubmit: function () {
      this.loading = true
      this.errors = {}
      this.$store.dispatch('addNewsletterSubscriberRequest', { id: this.dashboardId, value: { email: this.addEmailValue } }).then(() => {
        this.addEmailValue = ''
        this.getNewsletterSubscribers()
        this.loading = false
      }).catch(errors => {
        this.errors = errors.response.data
        this.loading = false
      })
    },
    deleteNewsletterSubscriber: function(id){
      this.$store.dispatch('deleteNewsletterSubscriberRequest', { id: this.dashboardId, value: { id: id } }).then(() => {
        this.addEmailValue = ''
        this.getNewsletterSubscribers()
        this.loading = false
      }).catch(errors => {
        this.errors = errors.response.data
        this.loading = false
      })
    }
  },
}
</script>