<template>
    <div>
        <v-main>
            <v-container fluid>
                <v-card>
                    <v-toolbar dark flat color="primary">
                        <v-toolbar-title>Liste des utilisateurs</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn color="secondary" depressed @click="createItem">Nouvel utilisateur</v-btn>
                            <v-dialog v-model="dialogEdit" max-width="800px">
                                <UserForm ref="UserEditForm" v-model="editedUser" :action="action" 
                                    @success="userCreatedOrUpdated" 
                                    @cancel="dialogEdit = false"/>
                            </v-dialog>
                            <v-dialog v-model="dialogShow" max-width="800px">
                                <UserCard :user="shownUser"/>
                            </v-dialog>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-card-text>
                        <v-text-field
                            v-model="search"
                            append-icon="search"
                            label="Rechercher"
                            single-line
                            hide-details
                            ></v-text-field>
                        

                        <v-data-table 
                            :headers="headers"
                            :items="users"
                            :options.sync="options"
                            :page.sync="options.page"
                            :items-per-page.sync="options.itemsPerPage"
                            :server-items-length="total"
                            :multi-sort="false"
                            :loading="loading"
                            class="mt-4"
                        >
                            <template v-slot:item="props">
                                <tr>
                                    <td data-label="Nom">{{ props.item.name }}</td>
                                    <td data-label="Email">{{ props.item.email }}</td>
                                    <td data-label="Rôle">{{ props.item.role_name }}</td>
                                    <td data-label="Activé">
                                        <v-icon small color="green" v-if="props.item.is_active">done</v-icon>
                                        <v-icon small color="red" v-if="!props.item.is_active">clear</v-icon>
                                    </td>
                                    <td class="justify-center layout px-0">
                                        <v-icon small class="mr-2" @click="showItem(props.item)">remove_red_eye</v-icon>
                                        <v-icon small class="mr-2" @click="editItem(props.item)">edit</v-icon>
                                        <v-icon small @click="removeClientUserRelation(props.item)">delete</v-icon>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>

                    </v-card-text>
                </v-card>
            </v-container>
        </v-main>
    </div>
</template>

<script>
import UserForm from '@/components/forms/UserForm'
import UserCard from '@/components/UserCard'

export default {
    components: {
        UserForm,
        UserCard,
    },
    data () {
        return {
            users: [],
            editedIndex: -1,
            editedUser: {},
            shownUser: false,
            action: 'create',
            options: {},
            total: 0,
            rowsPerPageItems: [10, 20, 40, 100],
            headers: [
                {
                    text: 'Nom',
                    align: 'left',
                    value: 'name'
                },
                { 
                    text: 'Email', 
                    align: 'left', 
                    value: 'email' 
                },
                { 
                    text: 'Rôle', 
                    align: 'left', 
                    value: 'role_name',
                    sortable: false
                },
                { 
                    text: 'Activé', 
                    align: 'left', 
                    value: 'is_active' ,
                    sortable: false
                },
                {
                    text: '',
                    value: '',
                    sortable: false
                }
            ],
            search: '',
            dialogEdit: false,
            dialogShow: false,
            loading: false,
        }
    },
    watch: {
        options: {
            handler () {
                this.optionsHandler()
            },
            deep: true
        },
        search: {
            handler () {
                this.optionsHandler()
            },
            deep: true
        }
    },
    methods: {
        optionsHandler(){
            this.loading = true
            this.getUsers()
                .then(result => {
                    this.users = result.results
                    this.total = result.total
                    this.loading = false
                })
        },
        getUsers () {
            let params= { 
                search: this.search, 
                page: this.options.page,
                per_page: this.options.itemsPerPage,
                sort: this.options.sortBy.length > 0 ? this.options.sortBy[0] : null,
                direction: this.options.sortDesc.length && this.options.sortDesc[0] ? 'desc' : 'asc'
            }
            return this.$store.dispatch('usersRequest', params)
        },
        showItem (user) {
            this.shownUser = user
            this.dialogShow = true
        },
        createItem (){
            console.log('createItem')
            this.action = 'create'
            this.editedUser = {}
            this.dialogEdit = true
        },
        editItem (user) {
            this.action = 'update'
            this.editedIndex = this.users.indexOf(user)
            this.editedUser = user
            this.dialogEdit = true
        },
        removeClientUserRelation (user) {
            if (confirm('Êtes-vous sûr de vouloir supprimer cet utilisateur ?')) {
                const client = this.$store.getters.getClient()
                this.$store.dispatch('clientUserRelationDeleteRequest', { id: user.id, datas: {client_id: client.id }})
                    .then(() => {
                        this.$store.commit('setSnack', {'type': 'success', 'msg': 'Utilisateur supprimé !'});
                        this.optionsHandler()
                    })
                    .catch((err) => {
                        this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data});
                    })
            }
        },
        userCreatedOrUpdated(){
            this.dialogEdit = false
            this.editedUser = {}
            this.optionsHandler()
        }
    },
}
</script>
