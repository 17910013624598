import Vue from 'vue'

const state = {
    status: '',
    client: null,
}

const getters = {
    getClient: state => () => state.client,
}

const actions = {
    /*clientRequest: async ({commit}) => {
        var envs = process.env
        return new Promise((resolve, reject) => {
            commit('clientRequest')
            Vue.axios.post('/oauth/token', {
                grant_type: 'client_credentials',
                client_id: envs.VUE_APP_API_OAUTH_ID,
                client_secret: envs.VUE_APP_API_OAUTH_SECRET
            }).then(async (oauthResp)=>{
                let access_token = 'Bearer ' + oauthResp.data.access_token
                await Vue.axios.get('/api/clients/'+process.env.VUE_APP_API_CLIENT_ID, { headers: { Authorization: access_token } })
                    .then((result) => {
                        commit('clientSuccess', result.data)
                        resolve(result.data)
                    })
                    .catch(err => {
                        commit('clientError')
                        reject(err)
                    })
            }).catch(err => {
                commit('clientError')
                reject(err)
            }) 
        })
    },*/
    clientsRequest: async ({commit}, queries) => {
        let url = '/api/clients/req'

        return new Promise((resolve, reject) => {
            commit('clientRequest')
            Vue.axios.post('/oauth/token', {
                grant_type: 'client_credentials',
                client_id: process.env.VUE_APP_API_OAUTH_ID,
                client_secret: process.env.VUE_APP_API_OAUTH_SECRET
            }).then(async (oauthResp)=>{
                const access_token = 'Bearer ' + oauthResp.data.access_token
                await Vue.axios.get(url,{ params: queries, headers: { Authorization: access_token } })
                    .then(result => {
                        commit('clientSuccess', result.data)
                        resolve(result.data)
                    })
                    .catch(err => {
                        commit('clientError');
                        reject(err)
                    })
            })
        })
    }
}

const mutations = {
    clientRequest: (state) => {
        state.status = 'loading';
    },
    clientSuccess: (state, resp) => {
        state.status = 'success';
        state.client = resp;
    },
    clientError: (state) => {
        console.log('clientError',state)
        state.status = 'error';
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}