<template>
    <v-card>
        <v-toolbar dark flat color="primary">
            <v-toolbar-title>Copier le tableau de bord</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
            </v-toolbar-items>
        </v-toolbar>
        <v-divider/>
        <form v-on:submit.prevent="onSubmit">
            <v-card-text>
                <v-text-field
                  name="name"
                  label="Tableau de bord"
                  id="id"
                  disabled
                  :value="dashboard.name + ' (@' + dashboard.user.name + ')'"
                ></v-text-field>
                <v-autocomplete
                    v-model="users"
                    :items="searchedUsers"
                    :search-input.sync="searchUser"
                    label="Utilisateurs"
                    item-text="name"
                    item-value="id"
                    return-object
                    multiple
                    chips
                    deletable-chips
                ></v-autocomplete>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="cancel" depressed>Annuler</v-btn>
                <v-btn color="primary" depressed type="submit" :disabled="loading" :loading="loading">Sauvegarder</v-btn>
            </v-card-actions>
        </form>
    </v-card>
</template>

<script>
export default {
    props: {
        dashboard: { 
            type: Object,
            required: true,
        },
    },
    data () {
        return {
            loading: false,
            users: [],
            searchUser: null,
            searchedUsers: []
        }
    },
    watch: {
        searchUser (val) {
            //if( !val || ( this.dashboard.user && this.dashboard.user.name == val ) ) return
            this.$store.dispatch('usersRequest', {search: val}).then((response)=>{
                this.searchedUsers = response.results
            })
        }
    },
    methods: {
        onSubmit() {
            this.$store.dispatch('dashboardCloneRequest', {id: this.dashboard.id, users: this.users.map(user => user.id)})
                .then(() => {
                    this.$emit('success')
                })
                .catch( err => {
                    this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data});
                } )
        },
        cancel() {
            this.$emit('cancel')
        },
        
    }
}
</script>