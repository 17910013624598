<template>
  <v-card>
    <v-toolbar dark flat color="primary">
      <v-toolbar-title>Ajouter un marque-page personnalisé</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <form v-on:submit.prevent="onSubmit">
        <v-row>
          <v-col cols="12" v-if="error">
            <v-alert type="error" outlined dense>
              {{ error }}
            </v-alert>
          </v-col>
          <v-col cols="10">
            <v-text-field v-model="link" name="link" :error-messages="errors.name" label="URL de l'article" required />
          </v-col>
          <v-col shrink align-self="center" md="2" cols="2">
            <v-btn block depressed color="primary" @click="fetchWebPage"
              :disabled="!article.link || !validURL(article.link)">
              <v-icon x-large>http</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="showForm">
          <v-col cols="4">
            <v-img :src="article.picture_url" v-if="article.picture_url" />
            <div class="d-flex fill-height grey lighten-2 align-center justify-center" v-else>
              <v-btn depressed outlined color="secondary" @click="openFileDialog">Ajouter une image</v-btn>
              <input type="file" ref="file" style="display: none" accept="image/png, image/jpeg"
                @change="onFileChange" />
            </div>
          </v-col>
          <v-col cols="8" class="px-0">
            <v-col cols="12">
              <v-text-field v-model="article.title" :error-messages="errors.title" label="Titre de l'article"
                required />
            </v-col>
            <v-col cols="12">
              <v-textarea name="content" label="Description" v-model="article.content"></v-textarea>
            </v-col>
          </v-col>
          <v-col cols="12">
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn block depressed color="secondary" @click="submitForm" :loading="submitting">
                Ajouter le marque-pages
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
        <v-row class="text-center" v-else>
          <v-col cols="12" v-if="loading">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </v-col>
        </v-row>
      </form>
    </v-card-text>
  </v-card>
</template>

<script>

import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  props: ["dashboard"],
  data() {
    return {
      link: "",
      ready: false,
      submitting: false,
      errors: {},
      formData: new FormData()
    }
  },
  watch: {
    "link": function (value) {
      return this.updateArticle({ ...this.article, link: value })
    }
  },
  computed: {
    ...mapGetters({ "article": "getArticle", "loading": "getArticleLoading", "error": "getArticleLoadingError" }),
    showForm: function () {
      return (this.article.title || this.article.content || this.article.picture_url);
    }
  },
  mounted() {
    this.ready = true
  },
  methods: {
    ...mapMutations(['updateArticle', 'updateArticleLoadingError']),
    ...mapActions(['fetchWebPage', 'postArticle']),
    validURL(str) {
      var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
      return !!pattern.test(str);
    },
    openFileDialog() {
      this.updateArticleLoadingError(false)
      this.$refs.file.click()
    },
    onFileChange(e) {
      e.preventDefault()
      const uploadedFile = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];

      // check max size
      const maxSize = 1024 * 1024 //1Mo
      if (uploadedFile.size > maxSize) {
        this.updateArticleLoadingError("La taille de votre fichier est supérieure à la limite authorisée (1Mo)")
        return
      }
      // preview
      const pictureUrl = URL.createObjectURL(uploadedFile);
      this.updateArticle({ ...this.article, picture_url: pictureUrl })
      // append formData
      this.formData.append('file', uploadedFile)
    },
    submitForm() {
      this.submitting = true;
      this.errors = {}

      this.formData.append('dashboard_id', this.dashboard.id)
      this.formData.append('link', this.link)
      this.formData.append('title', this.article.title)
      this.formData.append('content', this.article.content)

      this.postArticle(this.formData)
        .then(() => {
          this.$emit('created');
          this.updateArticle({});
        })
        .catch((err) => {
          this.updateArticleLoadingError(err)
        }
        )
        .finally(() => {
          this.submitting = false;
        });
    },

  }
}
</script>
