<template>
    <v-container>
        <v-layout column align-center justify-center>
            <v-flex>
                <v-progress-circular
                    indeterminate
                    color="primary"
                ></v-progress-circular>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    export default {}
</script>