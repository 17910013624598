<template>
    <v-card>
        <v-toolbar dark flat color="secondary">
            <v-toolbar-title>Connexion</v-toolbar-title>
        </v-toolbar>
        <form v-on:submit.prevent="onSubmit">
            <v-card-text>
                <v-text-field
                    v-model="email"
                    :error-messages="emailErrors"
                    name="email"
                    label="E-mail"
                    required
                    @input="$v.email.$touch()"
                    @blur="$v.email.$touch()"
                    ></v-text-field>
                <v-text-field
                    v-model="password"
                    name="password"
                    :append-icon="show_password ? 'visibility' : 'visibility_off'"
                    :error-messages="passwordErrors"
                    :type="show_password ? 'text' : 'password'"
                    label="Mot de passe"
                    counter
                    @click:append="toggleShow"
                    @input="$v.password.$touch()"
                    @blur="$v.password.$touch()"
                    ></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-btn block depressed color="primary" type="submit" :disabled="loading" :loading="loading">
                    Se connecter
                </v-btn>
            </v-card-actions>
            <v-card-actions>
                <v-btn block depressed :to="{ name: 'password-reset' }">
                    Mot de passe oublié ?
                </v-btn>
            </v-card-actions>
        </form>
    </v-card>
</template>

<script>
    import { required, email } from 'vuelidate/lib/validators'

    export default {
        data () {
            return {
                'email': '',
                'password': '',
                'show_password': false,
                'loading': false,
            }
        },
        validations: {
            email: { required, email },
            password: { required }
        },
        computed: {
            emailErrors () {
                const errors = []

                if (!this.$v.email.$dirty) {
                    return errors
                }

                if (!this.$v.email.email) {
                    errors.push('E-mail non valide')
                }

                if (!this.$v.email.required) {
                    errors.push('E-mail est obligatoire')
                }

                return errors
            },
            passwordErrors () {
                const errors = []

                if (!this.$v.password.$dirty) {
                    return errors;
                }

                if (!this.$v.password.required) {
                    errors.push('Le mot de passe est obligatoire')
                }

                return errors
            }
        },
        methods: {
            login: function () {
                const { email, password } = this;
                this.$store.dispatch('authRequest', { email, password })
                    .then(() => {
                        let profile = this.$store.getters.getProfile()
                        if(profile.dashboards.length > 0){
                            this.$router.push( { name: 'dashboard', params: { id: profile.dashboards[0].id } })
                        }else{
                            this.$router.push({name:'dashboard-create'})
                        }
                        
                    })
                    .catch(() => {
                        this.loading = false;
                    })
            },
            toggleShow () {
                this.show_password = !this.show_password
            },
            onSubmit () {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                this.loading = true;
                this.login();
            }
        }
    }
</script>
