<template>
  <div>
    <v-main>
      <v-container fluid>
        <corpus-card :id="id" />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import CorpusCard from '@/components/CorpusCard'

export default {
  name: 'Corpus',
  components: {
    CorpusCard
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      //
    }
  },
  methods: {
    //
  }
}
</script>
