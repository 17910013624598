<template>
    <div>
        <v-main>
            <v-container fluid>
                <v-card v-if="ready">
                    <dashboard-card :id="id" @deleted="dashboardDeleted"/>
                </v-card>
            </v-container>
        </v-main>
    </div>
</template>

<script>
import DashboardCard from '@/components/DashboardCard'
export default {
    name: 'Dashboard',
    components: {
        DashboardCard
    },
    props: {
        id: { 
            type: [Number , String],
            required: true,
        },
    },
    data () {
        return {
            ready: false,
        }
    },
    computed: {
        profile() {
            return this.$store.getters.getProfile();
        },
    },
    mounted () {
        this.ready = true
    },
    methods: {
        dashboardDeleted() {  
            this.$store.dispatch('profileRequest').then( (profile) => {
                this.$router.push( { name: 'dashboard', params: { id: profile.dashboards[0].id } } )
            })
        }
    }
}
</script>
