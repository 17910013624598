import Vue from 'vue'

const state = {
    status: '',
    profile: localStorage.getItem('profile') ? JSON.parse(localStorage.getItem('profile')) : '',
}

const getters = {
    getProfile: state => () => state.profile,
    profile: state => state.profile,
    isProfileLoaded: state => !!state.profile,
}

const actions = {
    profileRequest: async ({commit, dispatch}) => {
        return new Promise((resolve, reject) => {
            commit('profileRequest')
            Vue.axios.get('/api/user')
                .then((resp) => {
                    commit('profileSuccess', resp.data);
                    resolve(resp.data)
                })
                .catch((err) => {
                    commit('userError');
                    // if resp is unauthorized, logout, to
                    dispatch('authLogout')
                    reject(err)
                })
            })
    },
    profileUpdateRequest: async ({commit}, queries) => {
        let url = `/api/users/${state.profile.id}`;
        return new Promise((resolve, reject) => {
            commit('profileRequest')
            Vue.axios.put(url, queries.datas)
                .then(result => {
                    commit('profileSuccess', result.data.user)
                    resolve(result.data.user)
                })
                .catch(err => {
                    commit('profileError');
                    reject(err)
                })
        })
    },
}

const mutations = {
    profileRequest: (state) => {
        state.status = 'loading';
    },
    profileSuccess: (state, resp) => {
        state.status = 'success';
        localStorage.setItem('profile',JSON.stringify(resp));
        Vue.set(state, 'profile', resp);
    },
    profileRemove: (state) => {
        localStorage.removeItem('profile');
        Vue.set(state, 'profile', null);
    },
    profileError: (state) => {
        state.status = 'error';
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}