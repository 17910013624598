<template>
  <div>
    <v-main>
      <v-container fluid>
        <v-card>
          <v-toolbar dark flat color="primary">
            <v-toolbar-title>Liste des corpus privés</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn color="secondary" depressed @click="dialogCreate = true">Nouveau corpus privé</v-btn>
              <v-dialog v-model="dialogCreate" :key="dialogKey">
                <corpus-form action="create" @success="dialogCreate = false, optionsHandler(), dialogKey++"
                  @cancel="dialogCreate = false, dialogKey++" />
              </v-dialog>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text>
            <v-text-field v-model="search" append-icon="search" label="Rechercher" single-line
              hide-details></v-text-field>

            <v-data-table :headers="headers" :items="corpuses" :options.sync="options" :page.sync="options.page"
              :items-per-page.sync="options.itemsPerPage" :server-items-length="total" :multi-sort="false"
              :loading="loading" class="mt-4">
              <template v-slot:item="props">
                <tr @click="$router.push({ name: 'admin/corpus', params: { id: props.item.id } })"
                  :style="{ cursor: 'pointer' }">
                  <td data-label="Nom">{{ props.item.name }}</td>
                  <td data-label="Hérité de">{{ props.item.parent ? props.item.parent.name : '' }}</td>
                </tr>
              </template>
            </v-data-table>

          </v-card-text>
        </v-card>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import CorpusForm from '@/components/forms/CorpusForm'
// import CorpusCard from '@/components/CorpusCard'

export default {
  name: 'Corpuses',
  components: {
    CorpusForm,
    // CorpusCard
  },
  data() {
    return {
      corpuses: [],
      options: {},
      total: 0,
      rowsPerPageItems: [10, 20, 40, 100],
      headers: [
        {
          text: 'Intitulé',
          align: 'left',
          value: 'name'
        },
        {
          text: 'Hérité de',
          align: 'left',
          value: 'parent.name',
          sortable: false
        }
      ],
      search: '',
      dialogCreate: false,
      dialogKey: 0,
      loading: false
    }
  },
  watch: {
    options: {
      handler() {
        this.optionsHandler()
      },
      deep: true
    },
    search: {
      handler() {
        this.optionsHandler()
      },
      deep: true
    }
  },
  methods: {
    optionsHandler() {
      this.loading = true
      this.getCorpuses()
        .then(result => {
          this.corpuses = result.results
          this.total = result.total
          this.loading = false
        })
        .catch(error => {
          this.$store.commit('setSnack', { 'type': 'error', 'msg': error.response.data.message })
          this.loading = false
        })
    },
    getCorpuses() {
      let params = {
        search: this.search,
        is_private: 1,
        page: this.options.page,
        per_page: this.options.itemsPerPage,
        sort: this.options.sortBy.length > 0 ? this.options.sortBy[0] : null,
        direction: this.options.sortDesc.length && this.options.sortDesc[0] ? 'desc' : 'asc'
      }
      return this.$store.dispatch('corpusesRequest', params)
    }
  },
}
</script>
