import Vue from 'vue'

const state = {}

const getters = {}

const actions = {
  newslettersRequest: async (_, queries) => {
    let url = `/api/dashboards/${queries.dashboardId}/newsletters`
    return new Promise((resolve, reject) => {
      Vue.axios.get(url, { params: queries })
        .then(result => {
          resolve(result.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  newsletterEditRequest: async (_, queries) => {
    const url = `/api/dashboards/${queries.dashboardId}/newsletters/${queries.newsletterId}`

    return new Promise((resolve, reject) => {
      Vue.axios.put(url, queries.datas)
        .then(result => {
          resolve(result.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  newsletterCreateRequest: async (_, queries) => {
    const url = `/api/dashboards/${queries.dashboardId}/newsletters`

    return new Promise((resolve, reject) => {
      Vue.axios.post(url, queries)
        .then(result => {
          resolve(result.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  newsletterPreviewRequest: async (_, queries) => {
    let url = `/api/dashboards/${queries.dashboardId}/newsletters/preview`
    if (queries.newsletterId){
      url = `/api/dashboards/${queries.dashboardId}/newsletters/${queries.newsletterId}/preview`
    } 
    
    return new Promise((resolve, reject) => {
      Vue.axios.get(url, {})
        .then(result => {
          resolve(result.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  newsletterSubscribersRequest: async (_, queries) => {
    let url = `/api/dashboards/${queries.id}/newsletter-subscribers`
    return new Promise((resolve, reject) => {
      Vue.axios.get(url, {})
        .then(result => {
          resolve(result.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  addNewsletterSubscriberRequest: async (_, queries) => {
    let url = `/api/dashboards/${queries.id}/newsletter-subscribers`
    return new Promise((resolve, reject) => {
      Vue.axios.post(url, queries.value)
        .then(result => {
          resolve(result.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  deleteNewsletterSubscriberRequest: async (_, queries) => {
    let url = `/api/dashboards/${queries.id}/newsletter-subscribers/${queries.value.id}`
    return new Promise((resolve, reject) => {
      Vue.axios.delete(url)
        .then(result => {
          resolve(result.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  newsletterDocumentsRequest: async (_, queries) => {
    let url = `/api/dashboards/${queries.dashboardId}/newsletters/${queries.newsletterId}/documents`
    return new Promise((resolve, reject) => {
      Vue.axios.get(url, {})
        .then(result => {
          resolve(result.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  newsletterSendRequest: async (_, queries) => {
    let url = `/api/dashboards/${queries.dashboardId}/newsletters/${queries.newsletterId}/send`
    return new Promise((resolve, reject) => {
      Vue.axios.post(url, {})
        .then(result => {
          resolve(result.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  newsletterSendTestRequest: async (_, queries) => {
    let url = `/api/dashboards/${queries.dashboardId}/newsletters/test`
    return new Promise((resolve, reject) => {
      Vue.axios.post(url, {})
        .then(result => {
          resolve(result.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations,
}