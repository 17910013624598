<template>
    <v-container fluid fill-height>
        <v-layout align-center justify-center>
            <v-flex xs12 sm8 md4>
                <password-edit-form />
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import PasswordEditForm from '@/components/forms/PasswordEditForm'

export default {
  components: {
    PasswordEditForm
  }
}
</script>
