import Vue from 'vue'

const state = {
    article: {},
    articleLoading: false,
    articleLoadingError: null
}

const getters = {
    getArticle: () => state.article,
    getArticleLoading: () => state.articleLoading,
    getArticleLoadingError: () => state.articleLoadingError,
}

const actions = {
    fetchWebPage: async ({ commit }) => {
        commit('updateArticleLoading', true);
        commit('updateArticleLoadingError', null);
        commit('updateArticle', { link: state.article.link });
        Vue.axios.post("/api/documents/fetch", {
            link: state.article.link
        })
            .then((resp) => {
                commit('updateArticle', { ...state.article, ...resp.data });
            })
            .catch((error) => {
                if (error.response)
                    commit('updateArticleLoadingError', error.response.data.error);
                else
                    commit('updateArticleLoadingError', error);
            })
            .finally(() => {
                commit('updateArticleLoading', false);
            });

    },
    postArticle: async (_, formData) => {
        let url = `/api/documents/custom`;
        return new Promise((resolve, reject) => {
            Vue.axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(result => {
                    resolve(result.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    bookmarkUpdateRequest: async (_, formData) => {
        let url = `/api/documents/custom`;
        return new Promise((resolve, reject) => {
            Vue.axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(result => {
                    resolve(result.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}

const mutations = {

    updateArticle: (state, article) => {
        state.article = article;
    },
    updateArticleLoading: (state, loading) => {
        state.articleLoading = loading;
    },
    updateArticleLoadingError: (state, error) => {
        state.articleLoadingError = error;
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
}