<template>
  <div>
    <v-main>
      <v-container fluid>
        <v-card>
          <v-toolbar dark flat color="primary">
            <v-toolbar-title>Liste des sources à approuver</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="9">
                <v-text-field v-model="search" append-icon="search" label="Rechercher" single-line
                  hide-details></v-text-field>
              </v-col>
              <v-col>
                <v-select v-model="state" :items="states" label="Status" hide-details clearable></v-select>
              </v-col>
            </v-row>

            <v-data-table :headers="headers" :items="sources" :options.sync="options" :page.sync="options.page"
              :items-per-page.sync="options.itemsPerPage" :server-items-length="total" :multi-sort="false"
              :loading="loading" class="mt-4">
              <template v-slot:item="props">
                <tr @click="$router.push({ name: 'admin/source', params: { id: props.item.id } })"
                  :style="{ cursor: 'pointer' }">
                  <td data-label="Nom">{{ props.item.name }}</td>
                  <td data-label="Type">{{ props.item.type_name }}</td>
                  <td data-label="Corpus">
                    <v-chip v-for="corpus in props.item.corpuses" :key="corpus.id" small>{{ corpus.name }}</v-chip>

                  </td>
                  <td data-label="Status">
                    <v-chip color="default" v-if="props.item.state == 'draft'" small>{{ props.item.state_name }}</v-chip>
                    <v-chip color="warning" v-if="props.item.state == 'quarantaine'" small>{{ props.item.state_name
                    }}</v-chip>
                    <v-chip color="success" v-if="props.item.state == 'active'" small>{{ props.item.state_name }}</v-chip>
                    <v-chip color="error" v-if="props.item.state == 'desactive'" small>{{ props.item.state_name
                    }}</v-chip>
                  </td>
                  <td class="justify-center text-right">
                    <v-btn v-if="props.item.state == 'draft'" depressed small color="success"
                      @click.stop="approveSource(props.item.id)">
                      Approuver
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>

          </v-card-text>
        </v-card>
      </v-container>
    </v-main>
  </div>
</template>

<script>

export default {
  name: 'Sources',
  components: {
    //
  },
  data() {
    return {
      sources: [],
      options: {},
      total: 0,
      rowsPerPageItems: [10, 20, 40, 100],
      headers: [
        {
          text: 'Nom',
          align: 'left',
          value: 'name'
        },
        {
          text: 'Type',
          align: 'left',
          value: 'type_name',
          sortable: false
        },
        {
          text: 'Corpus',
          align: 'left',
          value: 'corpuses',
          sortable: false
        },
        {
          text: 'Status',
          align: 'left',
          value: 'state',
          sortable: false
        },
        {
          text: '',
          value: '',
          sortable: false
        }
      ],
      search: '',
      state: 'draft',
      states: [
        { value: 'draft', 'text': 'Brouillon' },
        { value: 'active', 'text': 'Activé' },
        { value: 'desactive', 'text': 'Désactivé' },
        { value: 'quarantaine', 'text': 'Quarantaine' }
      ],
      loading: false
    }
  },
  watch: {
    options: {
      handler() {
        this.optionsHandler()
      },
      deep: true
    },
    search: {
      handler() {
        this.optionsHandler()
      },
      deep: true
    },
    state: {
      handler() {
        this.optionsHandler()
      },
      deep: true
    }
  },
  methods: {
    optionsHandler() {
      this.loading = true
      this.getSources()
        .then(result => {
          this.sources = result.results
          this.total = result.total
          this.loading = false
        })
        .catch(error => {
          this.$store.commit('setSnack', { 'type': 'error', 'msg': error.response.data.message })
          this.loading = false
        })
    },
    getSources() {
      let params = {
        search: this.search,
        state: this.state,
        page: this.options.page,
        per_page: this.options.itemsPerPage,
        sort: this.options.sortBy.length > 0 ? this.options.sortBy[0] : null,
        direction: this.options.sortDesc.length && this.options.sortDesc[0] ? 'desc' : 'asc'
      }
      return this.$store.dispatch('sourcesRequest', params)
    },
    approveSource(sourceId) {
      const data = {
        id: sourceId
      }
      this.$store.dispatch('sourceApprovalRequest', data)
        .then(() => {
          this.$store.commit('setSnack', { 'type': 'success', 'msg': 'Votre source a été approuvée' })
          this.optionsHandler()
        })
        .catch(error => {
          this.$store.commit('setSnack', { 'type': 'error', 'msg': error.response.data })
        })
    }
  },
}
</script>
