const state = {
    dashboard: '',
    corpus: '',
    query: '',
}

const getters = {
    getSearchParams: state => () => state,
}

const actions = {}

const mutations = {
    setDashboard (state, dashboard) {
      state.dashboard = dashboard
    },
    setCorpus (state, corpus) {
      state.corpus = corpus
    },
    setQuery (state, query) {
      state.query = query
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
}