<template>
    <v-card>
        <v-toolbar dark flat dense color="primary">
            <v-icon v-if="!showTag" @click="showTag = !showTag">expand_more</v-icon> 
                <v-icon v-if="showTag" @click="showTag = !showTag">expand_less</v-icon> 
            <v-toolbar-title>
                {{ tag.name }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon class="handle" style="cursor: grab" small>drag_handle</v-icon> 
        </v-toolbar>
        <v-slide-y-transition>
            <v-list v-show="showTag">
                <draggable v-model="value.corpuses" handle=".handle" @end="$emit('sort')">
                    <div v-for="corpus in value.corpuses" :key="corpus.id">
                        <v-list-item 
                            @click="$emit('clickCombination', tag, corpus)"
                        >
                            <v-list-item-action>
                                <v-icon class="handle" style="cursor: grab" small @click.stop="">drag_handle</v-icon>
                            </v-list-item-action>
                            <v-list-item-title>
                                {{ corpus.name }}
                            </v-list-item-title>
                            <v-list-item-action>
                                <CounterBadge :corpusId="corpus.id" :tag="tag.name"/>
                            </v-list-item-action>
                        </v-list-item>
                        <v-divider/>
                    </div>
                </draggable>
            </v-list>
        </v-slide-y-transition>
    </v-card>
</template>
<script>
import draggable from 'vuedraggable'
import CounterBadge from '@/components/CounterBadge'
export default {
    components: {
        draggable,
        CounterBadge
    },
    props: {
        value: {
            type: Object,
            required: true
        },
    },
    data () {
        return {
            showTag: true,
            tag: this.value.tag,
        }
    },
}
</script>