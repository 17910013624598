<template>
    <div>
        <v-main>
            <v-container fluid>
                <UserCard v-if="profile" :user="profile"/>
            </v-container>
        </v-main>
    </div>
</template>

<script>

export default {
    data() {
        return {
            ready: false,
            profile: {},
        }
    },
    mounted () {
        this.profile = this.$store.getters.getProfile();
        this.ready = true;
    },
}
</script>
