import Vue from 'vue'

const state = {
    status: '',
    results: [],
}

const getters = {}

const actions = {
    resultsRequest: async ({commit}, params) => {
        let url = `/api/search`

        if(typeof params.corpus !== 'undefined'){
            url = `/api/search/${params.corpus}`
        }
        return new Promise((resolve, reject) => {
            commit('resultsRequest')
            Vue.axios.get(url, {params: params})
                .then(result => {
                    commit('resultsSuccess', result.data)
                    resolve(result.data)
                })
                .catch(err => {
                    commit('resultsError')
                    commit('setSnack', {'type': 'red', 'msg': err.response.data.message || err.response.data})
                    reject(err)
                })
        })
    },
    resultsCountRequest: async ({commit}, params) => {
        let url = `/api/search/${params.corpusId}?q=${params.tag}&count=1`;

        return new Promise((resolve, reject) => {
            commit('resultsCountRequest')
            Vue.axios.get(url)
                .then(result => {
                    commit('resultsCountSuccess', result.data)
                    resolve(result.data)
                })
                .catch(err => {
                    commit('resultsError')
                    commit('setSnack', {'type': 'red', 'msg': err.response.data.message || err.response.data})
                    reject(err)
                })
        })
    },
    resultsAggsRequest: async ({commit}, params) => {
        let url = `/api/search/aggregate`;

        return new Promise((resolve, reject) => {
            commit('resultsAggsRequest')
            Vue.axios.get(url, {params: params})
                .then(result => {
                    commit('resultsAggsSuccess', result.data)
                    resolve(result.data)
                })
                .catch(err => {
                    commit('resultsError')
                    commit('setSnack', {'type': 'red', 'msg': err.response.data.message || err.response.data})
                    reject(err)
                })
        })
    },
}

const mutations = {
    resultsRequest: (state) => {
        state.status = 'loading';
    },
    resultsSuccess: (state, resp) => {
        state.status = 'success';
        Vue.set(state, 'results', resp);
    },
    resultsCountRequest: (state) => {
        state.status = 'loading';
    },
    resultsCountSuccess: (state) => {
        state.status = 'success';
    },
    resultsAggsRequest: (state) => {
        state.status = 'loading';
    },
    resultsAggsSuccess: (state) => {
        state.status = 'success';
    },
    resultsError: (state) => {
        state.status = 'error';
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}