<template>
    <div>
        <v-list-item link class="pl-6" @click="open=true">
            <v-list-item-title>Proposer une source </v-list-item-title>
            <v-list-item-action><v-icon>queue</v-icon></v-list-item-action>
        </v-list-item>
        <v-dialog v-model="open" max-width="800px">
            <source-proposal-form @created="open=false"/>
        </v-dialog>
    </div>
</template>
<script>
    import SourceProposalForm from "./forms/SourceProposalForm";
    export default {
        components: {SourceProposalForm},
        data: () => ({
            open: false,
        }),
        computed: {
            profile() {
                return this.$store.getters.getProfile();
            },
        },
        mounted() {
            this.ready = true
        },
        methods: {

        },
    }
</script>