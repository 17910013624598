import Vue from 'vue'
import Router from 'vue-router'
import store from './vuex/store'
//auth & profile
import Login from './views/auth/Login'
import PasswordReset from './views/auth/PasswordReset'
import PasswordEdit from './views/auth/PasswordEdit'
import Profile from './views/Profile'
//user
import Dashboard from './views/Dashboard'
import DashboardCreate from './views/DashboardCreate'
//import Search from './views/Search'
import Bookmark from './views/Bookmark'
import Trends from './views/Trends'
import Indices from './views/Indices'
import Newsletter from './views/Newsletter'
//admin
import Dashboards from './views/admin/Dashboards'
import Users from './views/admin/Users'
import Corpuses from './views/admin/Corpuses'
import Corpus from './views/admin/Corpus'
import Sources from './views/admin/Sources'
import Source from './views/admin/Source'

Vue.use(Router)

const ifNotAuthenticated = (to, from, next) => {
    if (!store.getters.isAuthenticated) {
        next()
        return
    }
    let profile = store.getters.getProfile()
    if(typeof profile.dashboards != 'undefined' && profile.dashboards.length > 0){
        next( { name: 'dashboard', params: { id: profile.dashboards[0].id } } )
        return
    }else{
        next( { name: 'dashboard-create' } )
        return
    }
}

const ifAuthenticated = (to, from, next) => {
    // if authenticated, check route policy
    if (store.getters.isAuthenticated) {
        checkRoutePolicy(to, from, next)
        return
    }
    next('/')
}

import { mapRoutes, policies } from './plugins/policies'
const checkRoutePolicy = (to, from, next) => {
    // if policy rule exist
    
    if(Object.prototype.hasOwnProperty.call(mapRoutes, to.name)){
        let policy = mapRoutes[to.name]
        let user = store.getters.getProfile()
        // policy ok = go next
        if (policies[policy](user)) {
            next()
            return
        }
        // else, no next
        // next(redirectAuth)
        next(false)
        return
    }
    // else, no rule = go next
    next()
}

export default new Router({
    //mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'login',
            component: Login,
            beforeEnter: ifNotAuthenticated
        },
        {
            path: '/password/reset',
            name: 'password-reset',
            component: PasswordReset,
            beforeEnter: ifNotAuthenticated
        },
        {
            path: '/password/reset/:token',
            name: 'password-edit',
            component: PasswordEdit,
            beforeEnter: ifNotAuthenticated
        },
        {
            path: '/profile',
            name: 'profile',
            component: Profile,
            beforeEnter: ifAuthenticated
        },
        //dashboard
        {
            path: '/dashboard',
            name: 'dashboardGroup',
            component: { render: h => h('router-view') }, //nice trick ;)
            children: [
                {
                    path: '/dashboard/:id',
                    name: 'dashboard',
                    component: Dashboard,
                    beforeEnter: ifAuthenticated,
                    props: true
                },
                {
                    path: '/dashboard/create',
                    name: 'dashboard-create',
                    component: DashboardCreate,
                    beforeEnter: ifAuthenticated
                },
                /*{
                    path: '/dashboard/:id/search',
                    name: 'search',
                    component: Search,
                    beforeEnter: ifAuthenticated,
                    props: (route) => ({ id: route.params.id, corpus: route.query.corpus, query: route.query.q, read: route.query.read}) 
                },*/
                {
                    path: '/dashboard/:id/bookmark',
                    name: 'bookmark',
                    component: Bookmark,
                    beforeEnter: ifAuthenticated,
                    props: true
                },
                {
                    path: '/dashboard/:id/newsletter',
                    name: 'newsletter',
                    component: Newsletter,
                    beforeEnter: ifAuthenticated,
                    props: true
                },
                {
                    path: '/dashboard/:id/trends',
                    name: 'trends',
                    component: Trends,
                    beforeEnter: ifAuthenticated,
                    props: true
                },
                {
                    path: '/dashboard/:id/indices',
                    name: 'indices',
                    component: Indices,
                    beforeEnter: ifAuthenticated,
                    props: true
                },
            ]
        },
        
        
        //admin
        {
            path: '/admin',
            name: 'admin',
            component: { render: h => h('router-view') }, //nice trick ;)
            children: [
                {
                    path: 'users',
                    name: 'admin/users',
                    component: Users,
                    beforeEnter: ifAuthenticated
                },
                {
                    path: 'dashboards',
                    name: 'admin/dashboards',
                    component: Dashboards,
                    beforeEnter: ifAuthenticated
                },
                {
                    path: 'corpus',
                    name: 'admin/corpuses',
                    component: Corpuses,
                    beforeEnter: ifAuthenticated
                },
                {
                    path: 'corpus/:id',
                    name: 'admin/corpus',
                    component: Corpus,
                    beforeEnter: ifAuthenticated,
                    props: true
                },
                {
                    path: 'sources',
                    name: 'admin/sources',
                    component: Sources,
                    beforeEnter: ifAuthenticated
                },
                {
                    path: 'sources/:id',
                    name: 'admin/source',
                    component: Source,
                    beforeEnter: ifAuthenticated,
                    props: true
                }
            ]
        },
        
    ]
})
