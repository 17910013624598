import Vue from 'vue'

const state = {
    status: '',
}

const getters = {}

const actions = {
    sourceCreateRequest: async ({ commit }, queries) => {
        let url = `/api/sources`
        return new Promise((resolve, reject) => {
            Vue.axios.post(url, queries)
                .then(result => {
                    commit('sourceCreateSuccess', result.data)
                    resolve(result.data)
                })
                .catch(err => {
                    commit('sourceCreateError', err);
                    reject(err)
                })
        })
    },
    sourceEditRequest: async (_, queries) => {
        const url = `/api/sources/${queries.id}`;
        return new Promise((resolve, reject) => {
            Vue.axios.put(url, queries)
                .then(result => {
                    resolve(result.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    sourcesRequest: async (_, queries) => {
        const url = `/api/sources`
        return new Promise((resolve, reject) => {
            Vue.axios.get(url, {params: queries})
                .then(result => {
                    resolve(result.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    sourceRequest: async (_, queries) => {
        const url = `/api/sources/${queries.id}`
        return new Promise((resolve, reject) => {
            Vue.axios.get(url)
                .then(result => {
                    resolve(result.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    sourcePreviewRequest: async ( _ , queries) => {
        let url = `/api/sources/preview`
        return new Promise((resolve, reject) => {
            Vue.axios.post(url, queries)
                .then(result => {
                    resolve(result.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    sourceProposalRequest: async ({ commit }, queries) => {
        let url = `/api/sources/submit`
        return new Promise((resolve, reject) => {
            Vue.axios.post(url, queries)
                .then(result => {
                    commit('sourceCreateSuccess', result.data)
                    resolve(result.data)
                })
                .catch(err => {
                    commit('sourceCreateError', err);
                    reject(err)
                })
        })
    },
    sourceApprovalRequest: async (_, queries) => {
        const url = `/api/sources/${queries.id}/approve`;
        return new Promise((resolve, reject) => {
            Vue.axios.put(url, {})
                .then(result => {
                    resolve(result.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    sourcePublishToParentRequest: async (_, queries) => {
        const url = `/api/sources/${queries.sourceId}/parent-publish/${queries.childCorpusId}`;
        return new Promise((resolve, reject) => {
            Vue.axios.put(url, {})
                .then(result => {
                    resolve(result.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    sourceDeleteRequest: async (_, queries) => {
        let url = `/api/sources/${queries.id}`;

        return new Promise((resolve, reject) => {
            Vue.axios.delete(url)
                .then(result => {
                    resolve(result)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}

const mutations = {
    sourceCreateSuccess: (state) => {
        state.status = 'success';
    },
    sourceCreateError: (state) => {
        state.status = 'error';
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}