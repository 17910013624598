import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth'
import profile from './modules/profile'
import user from './modules/user'
import snackbar from './modules/snackbar'
import corpus from './modules/corpus'
import tag from './modules/tag'
import results from './modules/results'
import doc from './modules/document'
import client from './modules/client'
import dashboard from './modules/dashboard'
import bookmarks from './modules/bookmarks'
import source from './modules/source'
import message from './modules/message'
import search from './modules/search'
import newsletter from './modules/newsletter'

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        profile,
        user,
        snackbar,
        corpus,
        tag,
        results,
        doc,
        client,
        dashboard,
        bookmarks,
        source,
        message,
        search,
        newsletter
    }
});