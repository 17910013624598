<template>
  <v-card>
    <v-toolbar dark flat color="primary">
      <v-toolbar-title>Modifier un marque-page</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <form v-on:submit.prevent="submitForm">
        <v-alert v-if="error" type="error" outlined dense class="mt-2 mb-0">
          {{ error }}
        </v-alert>
        <v-row>
          <v-col cols="4">
            <div class="d-flex grey lighten-2 align-center justify-center">
              <v-img :src="bookmark.picture_url" v-if="bookmark.picture_url" />
              <input type="file" ref="file" style="display: none" accept="image/png, image/jpeg"
                @change="onFileChange" />
            </div>
            <div class="d-flex align-center justify-center mt-2">
              <v-btn depressed outlined color="secondary" @click="openFileDialog">Modifier l'image</v-btn>
            </div>
          </v-col>
          <v-col cols="8" class="px-0">
            <v-col cols="12">
              <v-text-field v-model="bookmark.title" label="Titre de l'article" required />
            </v-col>
          </v-col>
          <v-col cols="12">
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn block depressed color="secondary" @click="submitForm" :loading="submitting">
                Enregistrer
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </form>
    </v-card-text>
  </v-card>
</template>

<script>

//import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  props: ["value"],
  data() {
    return {
      bookmark: {},
      submitting: false,
      error: null,
      formData: new FormData()
    }
  },
  watch: {
    //
  },
  computed: {
    //
  },
  mounted() {
    this.bookmark = this.value
  },
  methods: {
    openFileDialog() {
      this.$refs.file.click()
    },
    onFileChange(e) {
      e.preventDefault()
      const uploadedFile = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];

      // check max size
      const maxSize = 1024 * 1024 //1Mo
      if (uploadedFile.size > maxSize) {
        this.error = "La taille de votre fichier est supérieure à la limite authorisée (1Mo)"
        return
      }
      // preview
      const pictureUrl = URL.createObjectURL(uploadedFile);
      this.bookmark = { ...this.bookmark, picture_url: pictureUrl }
      // append formData
      this.formData.append('file', uploadedFile)
    },
    submitForm() {
      this.submitting = true;

      this.formData.append('_method', 'PUT')
      this.formData.append('document_id', this.bookmark.id)
      this.formData.append('dashboard_id', this.bookmark.bookmark.dashboard_id)
      this.formData.append('title', this.bookmark.title)

      this.$store.dispatch('bookmarkUpdateRequest', this.formData)
        .then((resp) => {
          this.$emit('save', resp)
          this.$emit('close')
        })
        .catch((err) => {
          this.error = err
        }
        )
        .finally(() => {
          this.submitting = false;
        });
    },

  }
}
</script>
