<template>
    <v-main>
        <v-container fluid>
            <indices-card :id="id" :key="id"/>
        </v-container>
    </v-main>
</template>

<script>
import IndicesCard from '@/components/IndicesCard' 
export default {
    components: {
        IndicesCard
    },
    props: {
        id: { 
            type: [Number , String],
            required: true,
        },
    },
}
</script>
