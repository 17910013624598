<template>
	<v-card v-if="dashboardReady" :key="id">
        <v-toolbar dark flat color="primary">
            <v-toolbar-title>
                <span class="overline">[Tableau de bord]</span> {{ dashboard.name }} - Tendances
            </v-toolbar-title>
        </v-toolbar>
        <v-card-actions>
            <SearchForm v-model="searchParams" 
                :dashboard-id="dashboard.id"
                :show-dates-filter="true"
                :show-read-filter="false"
                :show-actions="false"
                mode="multiple"/>
        </v-card-actions>
        <v-card-text>
            <Loader v-if="!chartReady"/>
            <canvas id="chart" v-if="chartReady"></canvas>
        </v-card-text>
    </v-card>
</template>

<script>
import Vue from 'vue'
import SearchForm from '@/components/forms/SearchForm'
export default {
    components: {
        SearchForm
    },
    props: {
        id: { 
            type: [Number , String],
            required: true,
        },
    },
    data () {
        return {
            dashboardReady: false,
            dashboard: null,
            searchParams: {
                corpus: this.$route.query.corpus,
                q: this.$route.query.q
            },
            chartReady: true,
            chartData: {
                labels: [],
                datasets: []
            },
            chartOptions: {}
        }
    },
    watch: {
        searchParams: {
            deep: true,
            handler() {
                this.search()
            }
        }
    },
    mounted () {
        this.getDashboard().then(result => {
            this.dashboard = result
            this.dashboardReady = true
            this.search()
        })
    },
    methods: {
        search () {
            console.log(this.searchParams)
            if(this.searchParams.corpus && this.searchParams.q && this.searchParams.q.length > 0){
                this.chartReady = false
                this.getElasticData().then( () => {
                    let ctx = document.getElementById('chart')
                    //see plugins/chartjs.js
                    new Vue.Chart(ctx, {
                        type: "bar",
                        data: this.chartData,
                        options: {
                            responsive: true,
                            scales: {
                                x: {
                                    stacked: true,
                                },
                                y: {
                                    stacked: true
                                }
                            }
                        }
                    })
                })
            }
        },
        getDashboard () {
            return this.$store.dispatch('dashboardRequest', {id: this.id})
        },
        getElasticData(){
            this.chartReady = false
            this.chartData = {
                labels: [],
                datasets: []
            }

            /*let promises = []
            this.searchParams.q.forEach( tag => {
                let params = {...this.searchParams, q: tag}
                console.log('params', params)
                promises.push( this.$store.dispatch('resultsAggsRequest', params) )
            } )
            console.log('promises', promises)
            Promise.all(promises).then( () )*/

            return this.$store.dispatch('resultsAggsRequest', this.searchParams).then( result =>{
                console.log('resultsAggsRequest', result)

                this.searchParams.q.forEach( tag => {
                    result['aggregations'][tag]['count']['buckets'].forEach( row => {
                        if( ! this.chartData['labels'].some( label => label == row.key_as_string ) ){
                            this.chartData['labels'].push(row.key_as_string)
                        }
                    } )
                    let color = '#' + (Math.random().toString(16) + '0000000').slice(2, 8)
                    let dataset = {
                        label: tag,
                        borderColor: color,
                        backgroundColor: color,
                        data: result['aggregations'][tag]['count']['buckets'].map( row => row.doc_count )
                    }
                    this.chartData['datasets'].push(dataset)
                })
                
                //this.chartData['labels'] = result['aggregations']['count']['buckets'].map( row => row.key_as_string )
                console.log('chartData', this.chartData)
                this.chartReady = true
            })
        },
    }
}
</script>
