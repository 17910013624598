//import Vue from 'vue'
import { Chart, registerables } from 'chart.js'

export default {
    // called by Vue.use(Chartjs)
    install(Vue) {
        Chart.register(...registerables)
        Vue.Chart = Chart;
    }
};
