<template>
  <div>
    <v-main>
      <v-container fluid>
        <source-card :id="id" />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import SourceCard from '@/components/SourceCard'

export default {
  name: 'Source',
  components: {
    SourceCard
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      //
    }
  },
  methods: {
    //
  }
}
</script>
