import Vue from 'vue'

const state = {
    status: '',
    tags: [],
}

const getters = {}

const actions = {
    tagRequest: async ({commit}, queries) => {
        let url = `/api/tags/${queries.id}`;

        return new Promise((resolve, reject) => {
            commit('tagRequest')
            Vue.axios.get(url)
                .then(result => {
                    commit('tagSuccess', result.data)
                    resolve(result.data)
                })
                .catch(err => {
                    commit('tagError');
                    reject(err)
                })
        })
    },
    tagEditRequest: async ({commit}, queries) => {
        let url = `/api/tags/${queries.id}`;

        return new Promise((resolve, reject) => {
            commit('tagRequest')
            Vue.axios.put(url, queries.datas)
                .then(result => {
                    commit('tagSuccess', result.data.tag)
                    resolve(result.data.corpus)
                })
                .catch(err => {
                    commit('tagError');
                    reject(err)
                })
        })
    },
    tagCreateRequest: async ({commit}, queries) => {
        let url = `/api/tags`
        let profile = JSON.parse(localStorage.getItem('profile'))
        queries.user = profile.id
        return new Promise((resolve, reject) => {
            commit('tagRequest')
            Vue.axios.post(url, queries)
                .then(result => {
                    commit('tagSuccess', result.data.tag)
                    resolve(result.data.corpus)
                })
                .catch(err => {
                    commit('corpusError',err);
                    reject(err)
                })
        })
    },
    tagDeleteRequest: async ({commit}, queries) => {
        let url = `/api/tags/${queries.id}`;

        return new Promise((resolve, reject) => {
            commit('tagRequest')
            Vue.axios.delete(url)
                .then(result => {
                    commit('tagDeleteSuccess')
                    resolve(result)
                })
                .catch(err => {
                    commit('tagError');
                    reject(err)
                })
        })
    },
    tagsRequest: async ({commit}, queries) => {
        let url = '/api/tags'
        return new Promise((resolve, reject) => {
            commit('tagRequest')
            Vue.axios.get(url,{params:queries})
                .then(result => {
                    commit('tagsSuccess', result.data)
                    resolve(result.data)
                })
                .catch(err => {
                    commit('tagError');
                    reject(err)
                })
        })
    },
}

const mutations = {
    tagRequest: (state) => {
        state.status = 'loading';
    },
    tagSuccess: (state, resp) => {
        state.status = 'success';
        Vue.set(state, 'tag', resp);
    },
    tagsSuccess: (state, resp) => {
        state.status = 'success';
        Vue.set(state, 'tags', resp);
    },
    tagDeleteSuccess: (state) => {
        state.status = 'success';
    },
    tagError: (state) => {
        state.status = 'error';
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}